<template>
  <div
    class="svp-skeleton"
    data-test-id="svp-skeleton"
  >
    <div
      :class="type"
      :style="{ width: skeletonWidth, height: skeletonHeight }"
    />
  </div>
</template>

<script>
const colorOptions = ['text', 'rect', 'circle', 'button', 'header'];
export default {
  name: 'SvpSkeleton',
  props: {
    type: {
      type: String,
      default: 'rect',
      validator: (value) => colorOptions.includes(value)
    },
    size: {
      type: String
    },
    height: {
      type: String
    },
    width: {
      type: String
    }
  },
  computed: {
    skeletonWidth () {
      if (this.size) return this.size;
      if (this.width) return this.width;
      return '';
    },
    skeletonHeight () {
      if (this.size) return this.size;
      if (this.height) return this.height;
      return '';
    }
  }
};
</script>

<style lang="scss">
.svp-skeleton {
  .text, .rect, .circle, .button, .badge, .chip, .avatar, .header {
    background-color: $color-white5;
    cursor: wait;
    width: 100%;
    position: relative;
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      transform: translateX(-100%);
      animation: skeleton-loading 2.3s ease infinite;
      background: linear-gradient(90deg, rgba(2,0,36,0) 33%, rgba(242,242,246,1) 49%, rgba(0,212,255,0.01595247005988021) 66%);    }
  }
  .text {
    width: 200px;
    height: 15px;
  }
  .rect {
    height: 30px;
  }
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
  }
  .header {
    height: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  @keyframes skeleton-loading {
    to {
      transform: translateX(100%);
    }
  }
}
</style>
