<template>
  <svp-layout
    :use-aside="false"
    :use-footer="true"
    class="visitor-layout"
  >
    <template slot="header">
      <router-link :to="{ name: 'Home' }">
        <div
          v-if="!isPhone"
          class="logo-desktop"
        >
          <img
            :src="require(`@/assets/img/svp-logo.png`)"
            alt="desktop-logo"
            width="180px"
          >
        </div>
        <div
          v-else
          class="logo-mobile"
        >
          <img
            :src="require(`@/assets/img/svp-logo-mobile.svg`)"
            alt="mobile-logo"
            width="40px"
          >
        </div>
      </router-link>
      <div class="visitor-controls">
        <router-link
          v-if="userIsLogged"
          :to="{name: getIsLegislator ?  LegislatorDefaultPathName : TestCenterDefaultPathName}"
          class="btn btn--primary"
        >{{
          $t('goToProgram')
        }}
        </router-link>
        <router-link
          :to="{name: 'login'}"
          class="btn btn--primary"
        >{{ $t('homePage.signIn') }}</router-link>
        <language-switcher/>
      </div>
    </template>
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { LegislatorDefaultPathName } from '@/app/legislator/routes/utils';
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import LanguageSwitcher from '@/app/shared/components/ui/LanguageSwitcher.vue';
import { TestCenterDefaultPathName } from '@/app/testCenter/routes/utils';
import adaptiveMixin from '@/mixins/adaptiveMixin.vue';

export default {
  name: 'VisitorLayout',
  components: {
    LanguageSwitcher,
    SvpLayout
  },
  mixins: [adaptiveMixin],
  data () {
    return {
      TestCenterDefaultPathName,
      LegislatorDefaultPathName
    };
  },
  computed: {
    ...mapGetters(['getIsLegislator']),
    userIsLogged () {
      return this.$auth.check();
    }
  }
};
</script>

<style lang="scss">
.visitor-layout {
  //background-color: $color-white2;
  .svp-header {
    .content {
      .logo-desktop {
        width: 150px;
        @include media('>tablet') {
          width: 200px;
        }
      }

      .visitor-controls {
        display: flex;
        gap: 20px;

        a {
          font-size: 14px;
          @include media('>tablet') {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
