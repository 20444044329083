<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 0C9.46243 0 7 2.46243 7 5.5C7 8.53757 9.46243 11 12.5 11C15.5376 11 18 8.53757 18 5.5C18 2.46243 15.5376 0 12.5 0Z"
        fill="currentColor"
      />
      <path
        d="M6.04839 14C2.70795 14 0 16.6462 0 19.9105V21.7835C0 22.9706 0.880458 23.9828 2.07947 24.1742C8.98081 25.2753 16.0192 25.2753 22.9205 24.1742C24.1195 23.9828 25 22.9706 25 21.7835V19.9105C25 16.6462 22.2921 14 18.9516 14H18.4018C18.1042 14 17.8085 14.046 17.5256 14.1362L16.1297 14.5818C13.7712 15.3342 11.2288 15.3342 8.87031 14.5818L7.47431 14.1362C7.19144 14.046 6.89574 14 6.59818 14H6.04839Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconUser',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
