<template>
  <div :class="classes">
    <div
      v-if="$slots.header !== undefined || heading"
      class="svp-box__header"
    >
      <slot name="header">
        <h2 v-text="heading"/>
      </slot>
    </div>
    <transition-group
      name="slide-fade"
      tag="div"
      class="svp-box__main"
    >
      <svp-spinner
        v-show="status === 0"
        key="spinner"
      />
      <div
        v-show="status === 1"
        key="content"
      >
        <slot />
      </div>
    </transition-group>
  </div>
</template>

<script>
import SvpSpinner from '@/app/shared/components/appShell/spinner/SvpSpinner.vue';

export default {
  name: 'SvpBox',
  components: { SvpSpinner },
  props: {
    heading: {
      type: String
    },
    status: {
      type: [String, Number],
      default: 1 // - success, 0 - loading, 2 - error
    },
    useShadow: {
      type: Boolean,
      default: false // Should be default styles after redesign
    }
  },
  computed: {
    classes () {
      return {
        'svp-box': true,
        'svp-box--shadow': this.useShadow
      };
    }
  }
};

</script>

<style lang="scss">
.svp-box {
  &--shadow {
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.05);
  }
  &__header {
    padding: 20px;
    h2 {
      color: $color-black-text;
    }
    .link {
      font-weight: bold;
      text-transform: uppercase;
      @include media('>=desktop') {
        font-size: 12px;
      }

      &:hover {
        color: $color-secondary;
      }
    }
    @include media('>tablet') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__main {
    padding: 20px;
  }
}

</style>
