import Gateway from '@/api/index';

class RootAPI {
  partnerLogos = (url) => {
    return Gateway.get(`${url}/logos`);
  }

  getGroups = (url, params) => {
    return Gateway.get(`${url}/groups`, { params });
  }

  getCategories = (url, params) => {
    return Gateway.get(`${url}/categories`, { params });
  }

  getWithdrawalsList = (url, params) => {
    return Gateway.get(`${url}/withdrawals`, { params });
  }

  getPaymentsList = (url, params) => {
    return Gateway.get(`${url}/payments`, { params });
  }

  downloadCertificatesById = (url, id) => {
    return Gateway.get(`${url}/certificates/${id}/show_pdf`, { responseType: 'blob' });
  }

  downloadCertificates = (url, id) => {
    return Gateway.get(`${url}/withdrawals/${id}/certificates`);
  }

  sendCertificates = (url, id) => {
    return Gateway.get(`${url}/withdrawals/${id}/email_certificates`);
  }

  downloadInvoice = (url, id, type) => {
    return Gateway.get(`${url}/${type}/${id}/invoices`, { responseType: 'blob' });
  }

  downloadReport = (url, id, type) => {
    return Gateway.get(`${url}/${type}/${id}/reports`, { responseType: 'blob' });
  }

  downloadPassport = (url, id) => {
    return Gateway.get(`${url}/${id}/passport`, { responseType: 'blob' });
  }

  getActivityDates = (url, id) => {
    return Gateway.get(`${url}/test_centers/${id}/activity_dates`);
  }

  checkCertificateValidity = (url, params) => {
    return Gateway.get(`${url}/certificates/check_validity`, { params });
  }

  certificateValidate = (url, params) => {
    return Gateway.get(`${url}/certificates/validate`, { params });
  }

  checkLaborResult = (url, params) => {
    return Gateway.get(`${url}/labors`, { params });
  }

  getAssignedAssessors = (url, params) => {
    return Gateway.get(`${url}/assessors`, { params });
  }

  getTransactionsList = (url, params) => {
    return Gateway.get(`${url}/transactions`, { params });
  }

  getFlipperConfiguration = () => {
    return Gateway.get('/flipper/feature_flags');
  }
}

export default new RootAPI();
