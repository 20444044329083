import VModal from 'vue-js-modal';

import ConfirmModal from '@/app/shared/components/modals/ConfirmModal.vue';
import i18n from '@/app/shared/i18n';
import { confirmModalSettings } from '@/config';

const module = {
  state: {
    formTouched: false,
    showConfirmationModal: false,
    nextCall: null
  },
  getters: {
    formTouched: state => state.formTouched,
    showConfirmationModal: state => state.showConfirmationModal,
    nextCall: state => state.nextCall
  },
  mutations: {
    'SET_TOUCH' (state, flag) {
      state.formTouched = flag;
    },
    'SET_SHOW_CONFIRMATION_MODAL' (state, flag) {
      state.showConfirmationModal = flag;
    },
    'SET_NEXT_CALL' (state, next) {
      state.nextCall = next;
    }
  },
  actions: {
    showModalBetweenRouters ({ commit }, { to, from, next }) {
      const isRedirectFromPaymentPage = from.path.includes('payment');
      const isRedirectFromBookingSteps = from.path.includes('booking');
      const isRedirectFromRescheduleSteps = from.path.includes('reschedule');
      const isRedirectFromAssessorRefRegister = from.path.includes('register-assessor') && from.query?.ref_id;

      if (isRedirectFromBookingSteps || isRedirectFromRescheduleSteps || isRedirectFromAssessorRefRegister) {
        commit('SET_SHOW_CONFIRMATION_MODAL', true);
        commit('SET_NEXT_CALL', next);
        return;
      }

      const getModalTexts = () => {
        if (isRedirectFromPaymentPage) {
          return {
            headLabel: i18n.t('warning'),
            headText: i18n.t('messagesAlert.leavePaymentPage')
          };
        } else if (isRedirectFromBookingSteps) {
          return {
            headLabel: i18n.t('labor.bookingSteps.stopBookingProcess'),
            headText: i18n.t('labor.bookingSteps.stopBookingInfoLost'),
            confirmBtnText: i18n.t('labor.bookingSteps.stopBookingYes'),
            cancelBtnText: i18n.t('labor.bookingSteps.stopBookingNo')
          };
        } else {
          return {
            headLabel: i18n.t('warning'),
            headText: i18n.t('messagesAlert.leaveThisPage')
          };
        }
      };
      const modalTexts = getModalTexts();
      VModal.event.$modal.show(ConfirmModal,
        modalTexts,
        confirmModalSettings,
        {
          confirm (e) {
            commit('SET_TOUCH', false);
            commit('SET_STEP', 1);
            next();
          }
        });
    },
    setTouch ({ commit }, flag) {
      commit('SET_TOUCH', flag);
    },
    setShowConfirmationModal ({ commit }, value) {
      commit('SET_SHOW_CONFIRMATION_MODAL', value);
    },
    confirmRedirect ({ commit, state }) {
      commit('SET_SHOW_CONFIRMATION_MODAL', false);
      commit('SET_TOUCH', false);
      commit('SET_STEP', 1);
      state.nextCall();
      commit('SET_NEXT_CALL', null);
    }
  }
};

export default module;
