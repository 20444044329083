<script>
export default {
  name: 'AdaptiveMixin',
  data () {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isPhone () {
      return this.windowWidth < 768;
    }
  }
};
</script>
