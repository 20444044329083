<template>
  <div id="app">
    <div v-if="$auth.ready()">
      <router-view></router-view>
    </div>
    <portal-target name="app"></portal-target>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { ZENDESK_KEY } from '@/config';
import { getEnvironmentVar } from '@/utils/environments';

export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.$route.name === 'Home' ? 'Home Page | PACC' : '| PACC',
      htmlAttrs: {
        lang: this.$i18n.locale,
        dir: this.$i18n.locale === 'ar' ? 'rtl' : 'ltr'
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('metaTags.description')
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$t('metaTags.keywords')
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['lang', 'getIsAssessor']),
    isProduction () {
      return getEnvironmentVar('ENV') === 'production';
    },
    zESettings () {
      return {
        webWidget: {
          color: {
            theme: '#148287',
            launcherText: '#fff'
          },
          offset: {
            vertical: '60px'
          },
          position: {
            horizontal: this.lang === 'en' ? 'right' : 'left'
          },
          zIndex: 9999
        }
      };
    }
  },
  watch: {
    lang () {
      if (window.zE) {
        window.zE('webWidget', 'setLocale', this.lang === 'en' ? 'en-uk' : 'ar');
        window.zE('webWidget', 'updateSettings', this.zESettings);
      }
    }
  },
  async created () {
    if (this.isProduction) {
      this.initGtag();
      this.initHotjar();
    }

    try {
      /**
       * Get list of countries and saving data to store
       */
      !this.getIsAssessor && await this.getCountryList({ per_page: 250 });
      /**
       * Check user object in localStorage
       * if no exist then auth.logout()
       */
      await this.$auth.load().then(() => {
        if (this.$auth.check()) {
          const parsedUser = JSON.parse(localStorage.getItem('user'));
          this.setUserTimeZone(parsedUser.country.timezone_offset);
          this.setUserCountry(parsedUser.country.english_name);

          if (isEmpty(parsedUser)) {
            return this.$auth.logout();
          }
        }
      });
    } catch (err) {
      console.error(err);
      this.$toasted.error(this.$t('errorMessages.somethingWentWrong'));
    }
  },
  mounted () {
    this.insertZendesk();
  },
  methods: {
    ...mapActions(['getCountryList', 'setUserTimeZone', 'setUserCountry', 'getFeatureFlagsConfiguration']),
    initGtag () {
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-HMPM42FXCZ');
      document.head.appendChild(gTagScript);

      const gTag = document.createElement('script');
      gTag.innerHTML = 'window.dataLayer = window.dataLayer || [];\n' +
        '      function gtag(){dataLayer.push(arguments);}\n' +
        '      gtag(\'js\', new Date());\n' +
        '      gtag(\'config\', \'G-HMPM42FXCZ\');';
      document.head.appendChild(gTag);
    },
    initHotjar () {
      const hotjarScript = document.createElement('script');
      hotjarScript.innerHTML = '(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n' +
        'h._hjSettings={hjid:2817547,hjsv:6};\n' +
        'a=o.getElementsByTagName("head")[0];\n' +
        'r=o.createElement("script");\n' +
        'r.async=1;\n' +
        'r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
        'a.appendChild(r);\n' +
        '})(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=")';
      document.head.appendChild(hotjarScript);
    },
    updateZendeskOptions () {
      window.zESettings = this.zESettings;
      window.zE('webWidget', 'setLocale', this.lang === 'en' ? 'en-uk' : 'ar');
    },
    insertZendesk () {
      if (!ZENDESK_KEY) return;
      const url = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
      const zendeskScript = document.createElement('script');
      zendeskScript.id = 'ze-snippet';
      document.body.appendChild(zendeskScript);
      zendeskScript.onload = () => this.updateZendeskOptions();
      zendeskScript.src = url;
    }
  }
};
</script>

<style lang="scss" src="./assets/scss/_global.scss"></style>
