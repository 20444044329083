import { lazyLoading } from '@/app/shared/router/utils';
import TestCenterLayout from '@/app/testCenter/layout/TestCenterLayout.vue';
import { checkViewPermission, isUserTestCenter, TestCenterDefaultPathName } from '@/app/testCenter/routes/utils';

export const testCenterRoutes = [
  {
    path: '/test-center',
    component: TestCenterLayout,
    meta: {
      auth: true
    },
    beforeEnter (to, from, next) {
      isUserTestCenter(to, from, next);
    },
    children: [
      {
        path: 'upload',
        component: lazyLoading('testCenter', 'UploadPage'),
        name: 'TestCenterUploadPage'
      },
      {
        path: 'sessions-management',
        component: lazyLoading('testCenter', 'sessionsManagement/SessionsManagement'),
        name: 'TestCenterSessionsManagement'
      },
      {
        path: 'upload/labors/:id',
        component: lazyLoading('testCenter', 'LaborsGroupPage'),
        name: 'TestCenterUploadLabors'
      },
      {
        path: 'related-tc-assessors-list',
        component: lazyLoading('testCenter', 'AssessorsPageRelatedTC'),
        name: 'AssessorsPageRelatedTC'
      },
      {
        path: 'labors-list',
        component: lazyLoading('testCenter', 'LaborsPage'),
        name: 'TestCenterLaborsPage'
      },
      {
        path: 'labors-list/registered',
        component: lazyLoading('testCenter', 'RegisteredLaborsPage'),
        name: 'TestCenterRegisteredLaborsPage'
      },
      {
        path: 'assessors-list',
        component: lazyLoading('testCenter', 'AssessorsPage'),
        name: 'TestCenterAssessorsPage'
      },
      {
        path: 'view-assessor/info/:id',
        component: lazyLoading('testCenter', 'assessor/AssessorInformation'),
        name: 'TestCenterAssessorInformation',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'assessors', link: 'TestCenterAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/address/:id',
        component: lazyLoading('testCenter', 'assessor/AssessorAddress'),
        name: 'TestCenterAssessorAddress',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'assessors', link: 'TestCenterAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/details/:id',
        component: lazyLoading('testCenter', 'assessor/AssessorDetails'),
        name: 'TestCenterAssessorDetails',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'assessors', link: 'TestCenterAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/licenses/:id',
        component: lazyLoading('shared', 'AssessorDetails/AssessorLicenses/index'),
        name: 'TestCenterAssessorLicenses',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'assessors', link: 'TestCenterAssessorsPage' }
          ]
        }
      },
      {
        path: 'exams',
        component: lazyLoading('testCenter', 'sessionsManagement/Exams'),
        children: [
          {
            path: 'info/:id',
            component: lazyLoading('testCenter', 'sessionsManagement/SessionInformation'),
            name: 'ExamSessionInformation',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'testCenter.examSessions', link: 'TestCenterExamSessions' }
              ]
            }
          },
          {
            path: 'test-takers/:id',
            component: lazyLoading('testCenter', 'sessionsManagement/TestTakers'),
            name: 'ExamTestTakers',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'testCenter.examSessions', link: 'TestCenterExamSessions' }
              ]
            }
          },
          {
            path: 'practical-results/:id',
            component: lazyLoading('testCenter', 'sessionsManagement/PracticalResults'),
            name: 'ExamPracticalResults',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'testCenter.examSessions', link: 'TestCenterExamSessions' }
              ]
            }
          }
        ]
      },
      {
        path: 'sessions',
        component: lazyLoading('testCenter', 'sessionsManagement/Exams'),
        meta: {
          auth: true
        },
        children: [
          {
            path: 'info/:id',
            component: lazyLoading('testCenter', 'sessionsManagement/SessionInformation'),
            name: 'TestCenterSessionInformation',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'testCenter.sessionsManagement.title', link: 'TestCenterSessionsManagement' }
              ]
            }
          },
          {
            path: 'test-takers/:id',
            component: lazyLoading('testCenter', 'sessionsManagement/TestTakers'),
            name: 'TestCenterTestTakers',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'testCenter.sessionsManagement.title', link: 'TestCenterSessionsManagement' }
              ]
            }
          }
        ]
      },
      {
        path: 'exam-sessions',
        component: lazyLoading('testCenter', 'examSessions/ExamSessions'),
        name: 'TestCenterExamSessions'
      },
      {
        path: 'payment',
        component: lazyLoading('testCenter', 'payment/PaymentPage'),
        children: [
          {
            path: '',
            props: true,
            component: lazyLoading('testCenter', 'payment/PaymentTablePage'),
            name: 'TestCenterPaymentTablePage'
          },
          {
            path: 'steps',
            component: lazyLoading('testCenter', 'payment/PaymentSteps'),
            name: 'TestCenterPaymentSteps'
          },
          {
            path: 'confirmation',
            props: true,
            component: lazyLoading('testCenter', 'payment/PaymentConfirmation'),
            name: 'TestCenterPaymentConfirmation'
          },
          {
            path: 'confirmation/labors',
            props: true,
            component: lazyLoading('testCenter', 'payment/PaidLabors'),
            name: 'TestCenterPaidLabors'
          }
        ]
      },
      {
        path: 'centers',
        component: lazyLoading('testCenter', 'testCenters/TestCenters'),
        children: [
          {
            path: '',
            component: lazyLoading('testCenter', 'testCenters/TestCentersList'),
            props: true,
            name: 'TestCenterTestCentersList'
          },
          {
            path: 'add',
            component: lazyLoading('testCenter', 'testCenters/NewTestCenter'),
            props: true,
            beforeEnter: checkViewPermission,
            name: 'TestCenterNewTestCenter'
          },
          {
            path: ':id/edit',
            component: lazyLoading('testCenter', 'testCenters/EditTestCenter'),
            props: true,
            beforeEnter: checkViewPermission,
            name: 'TestCenterEditTestCenter'
          },
          {
            path: ':id',
            component: lazyLoading('testCenter', 'testCenters/ViewTestCenter'),
            props: true,
            name: 'TestCenterViewTestCenter'
          }
        ]
      },
      {
        path: 'history',
        props: true,
        component: lazyLoading('testCenter', 'TransactionHistoryPage'),
        name: 'TestCenterTransactionHistoryPage'
      },
      {
        path: 'reports',
        props: true,
        component: lazyLoading('testCenter', 'Reports'),
        name: 'TestCenterReports'
      },
      {
        path: 'certificate-validation',
        component: lazyLoading('testCenter', 'CertificateValidation'),
        name: 'TestCenterCertificateValidation'
      },
      {
        path: '/start-exam-stub',
        component: lazyLoading('shared', 'PrometricExamStub'),
        name: 'PrometricExamStubTestCenter'
      },
      {
        path: '*',
        redirect: { name: TestCenterDefaultPathName }
      }
    ]
  }
];
