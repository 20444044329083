import AssessorLayout from '@/app/assessor/layout/AssessorLayout.vue';
import { isUserAssessor } from '@/app/assessor/routes/utils';
import { lazyLoading } from '@/app/shared/router/utils';

export const assessorRoutes = [
  {
    path: '/assessor',
    component: AssessorLayout,
    meta: { auth: true },
    beforeEnter (to, from, next) {
      isUserAssessor(to, from, next);
    },
    children: [
      {
        path: 'unassigned-sessions',
        component: lazyLoading('assessor', 'unassignedSessions/UnassignedSessionsPage'),
        meta: { showAside: true },
        name: 'UnassignedSessionsPage'
      },
      {
        path: 'unassigned-sessions-info/:id',
        component: lazyLoading('assessor', 'sessions/DetailsPage/index'),
        name: 'UnassignedSessionsInfo',
        meta: {
          breadcrumb: [{ name: 'unassignedSessions', link: 'AssessorsPage' }],
          showAside: false
        }
      },
      {
        path: 'unassigned-sessions-test-takers/:id',
        component: lazyLoading('assessor', 'unassignedSessions/UnassignedSessionsTestTakers'),
        name: 'UnassignedSessionsTestTakers',
        meta: {
          breadcrumb: [{ name: 'unassignedSessions', link: 'UnassignedSessionsPage' }],
          showAside: false
        }
      },
      {
        path: 'assigned-sessions',
        component: lazyLoading('assessor', 'assignedSessions/AssignedSessionsPage'),
        meta: { showAside: true },
        name: 'AssignedSessionsPage'
      },
      {
        path: 'assigned-sessions-info/:id',
        component: lazyLoading('assessor', 'sessions/DetailsPage/index'),
        name: 'AssignedSessionsInfo',
        meta: {
          breadcrumb: [{ name: 'assignedSessionsItem', link: 'AssignedSessionsPage' }],
          showAside: false
        }
      },
      {
        path: 'assigned-sessions-test-takers/:id',
        component: lazyLoading('assessor', 'assignedSessions/AssignedSessionsTestTakers'),
        name: 'AssignedSessionsTestTakers',
        meta: {
          breadcrumb: [{ name: 'assignedSessionsItem', link: 'AssignedSessionsPage' }],
          showAside: false
        }
      },
      {
        path: 'practical-results/:id',
        component: lazyLoading('assessor', 'assignedSessions/PracticalResults'),
        name: 'AssessorPracticalResults',
        meta: {
          breadcrumb: [{ name: 'assignedSessionsItem', link: 'AssignedSessionsPage' }],
          showAside: false
        }
      },
      {
        path: '/start-exam-stub',
        component: lazyLoading('shared', 'PrometricExamStub'),
        name: 'PrometricExamStubAssessor'
      },
      {
        path: '*',
        redirect: { name: 'UnassignedSessionsPage' }
      }
    ]
  }
];
