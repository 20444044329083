import Vue from 'vue';
import Vuex from 'vuex';

import { langs } from '@/config';

import actions from './actions';
import getters from './getters';
import account from './modules/account';
import assessors from './modules/assessors';
import authentication from './modules/authentication';
import certificate from './modules/certificate';
import content from './modules/content';
import countries from './modules/countries';
import examSessions from './modules/examSessions';
import featureFlags from './modules/featureFlags';
import filter from './modules/filter';
import formTouched from './modules/formTouched';
import labors from './modules/labors';
import laborSessions from './modules/laborSessions';
import notifications from './modules/notifications';
import password from './modules/password';
import payment from './modules/payment';
import permissions from './modules/permissions';
import refreshToken from './modules/refreshToken';
import registration from './modules/registration';
import registrationAssessor from './modules/registrationAssessor';
import sessionsReport from './modules/sessionsReport';
import sidebar from './modules/sidebar';
import testCenters from './modules/testCenters';
import user from './modules/user';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    password,
    countries,
    labors,
    formTouched,
    testCenters,
    refreshToken,
    user,
    payment,
    permissions,
    notifications,
    content,
    certificate,
    account,
    registration,
    registrationAssessor,
    laborSessions,
    examSessions,
    assessors,
    sessionsReport,
    featureFlags,
    sidebar,
    filter
  },
  state: {
    lang: langs[0],
    pageLoading: false
  },
  getters,
  mutations,
  actions
});
