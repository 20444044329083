<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.316 12.4163C16.4183 12.5502 16.3686 12.7474 16.2129 12.8177C14.949 13.3884 13.9425 14.406 13.4086 15.6613C13.3295 15.8474 13.1492 15.9781 12.9418 15.9822C10.2123 16.0359 7.48077 15.9681 4.75599 15.7789L2.88197 15.6488C1.68316 15.5655 0.694371 14.7052 0.478353 13.5575C-0.0509975 10.7451 -0.141616 7.87148 0.209519 5.03302L0.546803 2.30656C0.709609 0.990463 1.8598 0 3.22532 0H6.06178C7.29007 0 8.28579 0.966576 8.28579 2.15891C8.28579 2.1983 8.31868 2.23022 8.35925 2.23022H19.1439C20.4567 2.23022 21.575 3.15622 21.782 4.41469L21.8612 4.89704C21.9128 5.21065 21.9585 5.52497 21.9981 5.83978C22.0231 6.03731 21.7993 6.17705 21.6132 6.09355C21.0608 5.84567 20.4456 5.70737 19.7972 5.70737C17.4045 5.70737 15.4649 7.5902 15.4649 9.9128C15.4649 10.8507 15.7813 11.7169 16.316 12.4163Z"
        fill="currentColor"
      />
      <path
        d="M20 7C18.3433 7 17 8.11925 17 9.5C17 10.8807 18.3433 12 20 12C21.6569 12 23 10.8807 23 9.5C23 8.11925 21.6569 7 20 7Z"
        fill="currentColor"
      />
      <path
        d="M15 17.5C15 15.0146 16.679 13 18.75 13H21.25C23.3211 13 25 15.0146 25 17.5C25 18.3284 24.4404 19 23.75 19H16.25C15.5597 19 15 18.3284 15 17.5Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconUserFolder',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
