<template>
  <header
    :class="{'with-aside': useAside}"
    class="svp-header"
  >
    <div class="content">
      <slot>
        <div
          v-if="useAside ? isTablet : true"
          class="ui-controls"
        >
          <router-link
            :to="{ name: 'Home' }"
            class="logo"
          >
            <img
              :src="require(`@/assets/img/svp-logo.png`)"
              alt="logo"
            >
          </router-link>
        </div>

        <div class="user-controls">
          <LanguageSwitcher
            v-if="useAside ? !isTablet : true"
            :use-new-view="true"
          />
          <NotificationsInfo
            v-if="useNotification"
            :use-new-view="true"
          />
          <svp-icon
            v-if="isTablet && useAside"
            class="sidebar-open-button"
            height="20px"
            icon-name="IconMenu"
            width="20px"
            @click.native="updateSidebar"
          />
          <UserInfo
            v-if="!isTablet"
            @click="updateSidebar"
          />
        </div>
      </slot>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import UserInfo from '@/app/shared/components/appShell/userInfo/UserInfo.vue';
import LanguageSwitcher from '@/app/shared/components/ui/LanguageSwitcher.vue';
import NotificationsInfo from '@/components/NotificationsInfo.vue';

export default {
  name: 'SvpHeaderNew',
  components: { UserInfo, NotificationsInfo, LanguageSwitcher },
  props: {
    sidebarIsOpen: {
      type: Boolean,
      default: false
    },
    useNotification: {
      type: Boolean,
      default: true
    },
    useAside: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapGetters(['getIsLegislator', 'getIsAssessor', 'accountInfo']),
    role () {
      if (this.getIsLegislator) return this.$t('userInfo.legislator');
      if (this.getIsAssessor) return this.$t('userInfo.assessor');
      return '';
    },
    isTablet () {
      return this.windowWidth < 768;
    },
    user () {
      return this.$auth.user() || {};
    },
    userLegislator () {
      return this.user.legislator || {};
    },
    logoSrc () {
      return this.accountInfo ? this.accountInfo.logo_url : this.userLegislator.logo_url;
    },
    showLogo () {
      return (this.accountInfo && this.getIsLegislator) ? this.accountInfo.show_logo : false;
    }
  },
  created () {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  destroyed () {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateSidebar () {
      this.$emit('update:sidebarIsOpen', !this.sidebarIsOpen);
    },
    updateWindowWidth () {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
.svp-header {
  width: 100%;
  height: 90px;
  right: 0;
  background-color: $color-white;
  border-bottom: 1px solid #E4E7ED;
  padding: 8px 20px;
  @include media('>tablet') {
    height: 70px;
    padding: 8px 25px;
  }

  .content {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .logo img {
      width: 130px;
      @include media('>tablet') {
        width: 160px;
      }
    }

    .user-controls {
      display: flex;
      align-items: center;
      gap: 10px;

      @include media('>tablet') {
        gap: 30px;
      }

      .svp-lang-switcher-old {
        display: none;
        @include media('>tablet') {
          display: block;
        }
      }

      .sidebar-open-button {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}

.with-aside {
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  @include media('>tablet') {
    width: calc(100vw - 264px);
  }

  .content {
    @include media('>tablet') {
      justify-content: flex-end;
    }
  }
}
</style>
