<template>
  <svp-box
    :use-shadow="true"
    class="notification-modal mb-0"
  >
    <template slot="header">
      <h2 v-text="$t(`${title}`)" />
      <button
        type="button"
        href="#"
        @click.prevent="closeAndUpdate"
        class="notification-modal_icon-close icon-close"
      />
    </template>
    <p
      v-text="text"
      class="notification-modal_text text-center mb-0"
    />
  </svp-box>
</template>

<script>

import SvpBox from '@/app/shared/components/appShell/box/SvpBox.vue';
export default {
  name: 'NotificationModal',
  components: {
    SvpBox
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    closeAndUpdate () {
      this.$parent.$emit('success-done');
      this.$emit('close');
    }
  }
};
</script>
