export const landingTranslations = {
  en: {
    landing: {
      header: {
        testCenters: 'Test centers',
        checkCertificate: 'Check certificate',
        laborResult: 'Labor result',
        partnership: 'Partnership'
      },
      heroSection: {
        titleStatic: 'Elevating Careers,',
        titleDynamic1: 'Unlocking New Opportunities',
        titleDynamic2: 'Fueling Professional Growth',
        titleDynamic3: 'Building Pathways to Success',
        titleDynamic4: 'Expanding Horizons',
        titleDynamic5: 'Paving the Way to Excellence',
        desc: 'Professional Accreditation Program helps workers stand out by verifying their skills, opening doors to new and better job opportunities.'
      },
      testCenterSection: {
        title: '<span>Find test center</span> in your country',
        country: 'Country',
        selectCountry: 'Select country',
        city: 'City',
        selectCity: 'Select City',
        occupation: 'Occupation',
        selectOccupation: 'Select Occupation',
        search: 'Search',
        noResult: 'There is no result',
        countries: {
          bangladesh: 'Bangladesh',
          india: 'India',
          pakistan: 'Pakistan',
          sriLanka: 'Sri-lanka'
        }
      },
      validationSection: {
        title: '<span>Check certificate</span> &<br> Exam result',
        certificate: {
          title: 'Check certificate <br> validity',
          desc: 'To ensure the authenticity of the certificate, please verify its validity through our online certificate verification system',
          checkCertificate: 'Check certificate'
        },
        laborResult: {
          title: 'Check labor result',
          desc: 'To check your labor result, you can visit the official labor department website and enter your credentials to access your test scores',
          checkResult: 'Check result'
        }
      },
      advantagesSection: {
        title: '<span>Advantages of</span> Professional Accreditation Program',
        enhanced: {
          title: 'Enhanced Workforce Competence',
          desc: 'The program ensures that professional workers have the qualifications and skills necessary to be competent in the Saudi labor market.'
        },
        quality: {
          title: 'Quality Assurance in Job Performance',
          desc: 'This program contributes to improving job performance and the quality of labor market outcomes by verifying professional qualifications.'
        },
        productivity: {
          title: 'Increased Productivity',
          desc: 'The completion of professional accreditation program requirements leads to increased productivity as employees are better equipped to perform their roles effectively.'
        },
        competitive: {
          title: 'Competitive Edge for Saudi Arabia',
          desc: 'Professional Accreditation Program enhances the competitiveness of the Saudi workforce globally.'
        }
      },
      partnership: {
        title: '<span>Partnership</span><br/> Skills Verification',
        desc: 'Partnerships are vital because they foster innovation and drive growth through shared resources and expertise',
        registerAsAssessor: 'Sign up as Assessor',
        loginAssessor: 'Sign in as Assessor'
      },
      partners: {
        title: 'Partners',
        goToWebsite: 'Go to website'
      }
    }
  },
  ar: {
    landing: {
      header: {
        testCenters: 'مراكز الاختبار',
        checkCertificate: 'شهادة الفحص',
        laborResult: 'نتيجة العمالة',
        partnership: 'الشراكة'
      },
      heroSection: {
        titleStatic: 'تطوير المسارات المهنية',
        titleDynamic1: 'فتح فرص جديدة',
        titleDynamic2: 'تعزيز النمو المهني',
        titleDynamic3: 'بناء المسارات نحو النجاح',
        titleDynamic4: 'توسيع الآفاق',
        titleDynamic5: 'تمهيد الطريق للتميز',
        desc: 'يساعد برنامج الاعتماد المهني العمالة الوافدة على التميز في سوق العمل السعودي، وذلك من خلال إثبات مهاراتهم ليحصلوا على فرص عمل أفضل'
      },
      testCenterSection: {
        title: '<span>ابحث عن مركز اختبار</span> في منطقتك',
        country: 'الدولة',
        selectCountry: 'اختر الدولة',
        city: 'المدينة',
        selectCity: 'إختر المدينة',
        occupation: 'المهنة',
        selectOccupation: 'إختر المهنة',
        search: 'البحث',
        noResult: 'لا توجد بيانات',
        countries: {
          bangladesh: 'بنغلاديش',
          india: 'الهند',
          pakistan: 'باكستان',
          sriLanka: 'سريلانكا'
        }
      },
      validationSection: {
        title: 'تأكد من الشهادة ونتيجة الامتحان',
        certificate: {
          title: 'تأكد من أهلية الشهادة',
          desc: 'لضمان صلاحية الشهادة، يُرجى التأكد منها عبر نظام التحقق الإلكتروني للشهادات في الموقع',
          checkCertificate: 'تأكد من صلاحية الشهادة'
        },
        laborResult: {
          title: 'تأكد من نتائج العمالة',
          desc: 'لتأكد من نتيجة العمالة في منشأتك، يمكنك زيارة موقع وزارة الموارد البشرية والتنمية الاجتماعية، وإدخال بيانات الاعتماد للوصول إلى نتيجة الاختبار',
          checkResult: 'استعرض النتيجة'
        }
      },
      advantagesSection: {
        title: 'منافع برنامج الاعتماد المهني',
        enhanced: {
          title: 'تعزيز خبرة القوى العاملة',
          desc: 'يهدف البرنامج لضمان حصول العمالة المهنية على المؤهلات والمهارات اللازمة، لرفع جودة مخرجات سوق العمل السعودي'
        },
        quality: {
          title: 'حفظ الجودة في الأداء الوظيفي',
          desc: 'يساهم البرنامج في تحسين الأداء الوظيفي وجودة مخرجات سوق العمل من خلال فحص المهارات المهنية والتحقق من المؤهلات الاكاديمية'
        },
        productivity: {
          title: 'رفع الإنتاجية',
          desc: 'اسكتمال متطلبات برنامج الاعتماد المهني يرفع الإنتاجية، وذلك لكون العاملين على أتم الاستعداد لأداء مهامهم باحترافية'
        },
        competitive: {
          title: 'أضافة قيمة تنافسية للمملكة العربية السعودية',
          desc: 'يعزز برنامج الاعتماد المهني القدرة التنافسية عالميًا للقوى العاملة في المملكة العربية السعودية'
        }
      },
      partnership: {
        title: '<span>الشراكة</span><br/>الفحص المهني',
        desc: 'تعزز الشراكات التعاون وتساهم في التنمية من خلال مشاركة الموارد والخبرات المشتركة',
        registerAsAssessor: 'سجل كمقيّم',
        loginAssessor: 'سجل الدخول كمقيّم'
      },
      partners: {
        title: 'الشركاء',
        goToWebsite: 'الموقع'
      }
    }
  }
};
