import CertificateApi from '@/api/certificate.api';
import { CERTIFICATE_PRICE } from '@/config';

const module = {
  state: {
    certificatePrice: CERTIFICATE_PRICE
  },
  getters: {
    certificatePrice: state => state.certificatePrice
  },
  mutations: {
    'SET_CERTIFICATE_PRICE' (state, price) {
      state.certificatePrice = price || CERTIFICATE_PRICE;
    }
  },
  actions: {
    async getCertificatePrice ({ commit, rootState }) {
      try {
        const response = await CertificateApi.getCertificatePrice(rootState.user.userSpace);
        const { price } = response.data;
        await commit('SET_CERTIFICATE_PRICE', price);
        return response;
      } catch (e) {
        await Promise.reject(e);
      }
    }
  }
};

export default module;
