<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.1129 18C1.72754 18 2.22581 18.5224 2.22581 19.1667V22.2778C2.22581 22.4926 2.3919 22.6667 2.59677 22.6667H20.4032C20.6081 22.6667 20.7742 22.4926 20.7742 22.2778V19.1667C20.7742 18.5224 21.2725 18 21.8871 18C22.5017 18 23 18.5224 23 19.1667V22.2778C23 23.7812 21.8374 25 20.4032 25H2.59677C1.16261 25 0 23.7812 0 22.2778V19.1667C0 18.5224 0.498263 18 1.1129 18Z"
        fill="currentColor"
      />
      <path
        d="M9.70305 0C8.97068 0 8.35975 0.572851 8.2958 1.31956C8.07705 3.8736 8.03846 6.44019 8.18023 8.99938C7.82857 9.01927 7.47706 9.04233 7.12571 9.06854L5.00499 9.22675C4.18731 9.28776 3.73023 10.2209 4.17167 10.9279C5.68377 13.3499 7.63215 15.4554 9.91296 17.1324L10.7626 17.757C11.2032 18.081 11.7968 18.081 12.2374 17.757L13.087 17.1324C15.3679 15.4554 17.3162 13.3499 18.8284 10.9279C19.2697 10.2209 18.8127 9.28776 17.995 9.22676L15.8743 9.06854C15.5229 9.04233 15.1714 9.01927 14.8197 8.99938C14.9615 6.44019 14.923 3.8736 14.7043 1.31955C14.6402 0.572851 14.0293 0 13.297 0H9.70305Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconUpload',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
