<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V7Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2929 2.29289C18.9024 2.68342 18.9024 3.31658 19.2929 3.70711L20.2929 4.70711C20.6834 5.09763 21.3166 5.09763 21.7071 4.70711C22.0976 4.31658 22.0976 3.68342 21.7071 3.29289L20.7071 2.29289C20.3166 1.90237 19.6834 1.90237 19.2929 2.29289Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconRosette',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
