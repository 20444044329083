const module = {
  state: {
    sideBarOpen: false
  },
  getters: {
    sideBarOpen: (state) => {
      return state.sideBarOpen;
    }
  },
  mutations: {
    'SET_SIDEBAR_STATE' (state, isOpen) {
      state.sideBarOpen = isOpen;
    }
  },
  actions: {
    setSidebarVisibility ({ commit }, isOpen) {
      commit('SET_SIDEBAR_STATE', isOpen);
    }
  }
};

export default module;
