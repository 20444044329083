<template>
  <svp-layout
    :aside-is-loading="!permissionsIsFetched"
    :nav-items="navList"
    :useAside="useAside"
  >
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import IconRosette from '@/app/shared/components/appShell/icons/IconRosette.vue';
import IconTransaction from '@/app/shared/components/appShell/icons/IconTransaction.vue';
import IconUserFolder from '@/app/shared/components/appShell/icons/IconUserFolder.vue';
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { getPermittedPath } from '@/services/userPermissions';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'TestCenterLayout',
  components: {
    SvpLayout
  },
  data () {
    return {
      pageTimeOut: 0,
      navItems: []
    };
  },
  computed: {
    ...mapGetters([
      'permissionsObject',
      'permissionsLabors',
      'permissionsPayment',
      'permissionsIsFetched',
      'permissionsTestCenters',
      'getIsTestCenterOwner'
    ]),
    isHideSessionsReportForLegislatorActiveFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_SESSIONS_AND_RESERVATIONS);
    },
    showAssessorsPageForTestCenterFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_VIEW_ASSESSORS_LIST);
    },
    showAssessorsPageForLegislatorFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_ASSESSORS_LIST);
    },
    tcStartExamFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_START_EXAM);
    },
    useAside () {
      return this.$route.meta?.showAside;
    },
    navList () {
      const navItems = [];
      const addNavItem = (icon, descriptionPath, routeName) => {
        navItems.push({ icon, descriptionPath, routeName });
      };

      if (this.getIsTestCenterOwner) {
        addNavItem(IconTransaction, 'nav.examSessions', 'TestCenterExamSessions');
        addNavItem(IconRosette, 'nav.sessionsManagement', 'TestCenterSessionsManagement');
      }

      if (this.getIsTestCenterOwner && this.showAssessorsPageForTestCenterFF) {
        addNavItem(IconUserFolder, 'assessors', 'TestCenterAssessorsPage');
      }
      return navItems;
    }
  },
  async created () {
    try {
      await this.getCertificatePrice();
    } catch (error) {
      console.log(error.response);
    }
  },
  methods: {
    ...mapActions(['SET_PAGE_LOADING', 'getCertificatePrice']),
    /**
     * @param {Function} cbFunction callback function
     * @param arguments You can pass arguments after 'cbFunction'
     * call example: fnWrapperWithLoader(cbFunction, obj, arr, str)
     */

    // why all this logic exist, what reason?
    fnWrapperWithLoader (cbFunction) {
      const args = Array.prototype.splice.call(arguments, 1);

      try {
        clearTimeout(this.pageTimeOut);

        this.SET_PAGE_LOADING(true); // Show loader

        cbFunction(...args);
      } catch (err) {
        console.error(err);
      }

      this.pageTimeOut = setTimeout(() => {
        this.SET_PAGE_LOADING(false); // Hide loader
      }, 1000);
    }
  }
};
</script>
