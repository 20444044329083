import RegistrationAssessorApi from '@/api/registrationAssessor.api';

const module = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /** AssessorRegistration
     * @param commit { Object} fields
     * @param data { Object} fields
     * @returns {Promise<unknown>}
     */
    async assessorRegistration ({ commit }, data) {
      return await RegistrationAssessorApi.assessorRegistration(data);
    },
    /** validateAssessorRegistration
     * @param commit { Object} fields
     * @param data type { Object} payload
     * @returns {Promise<unknown>}
     */
    validateAssessorRegistration ({ commit }, data) {
      return RegistrationAssessorApi.validateAssessorRegistration(data);
    },
    /** resendAssessorOtp
     * @param {type Object} fields
     * @returns {Promise<unknown>}
     */
    resendAssessorOtp ({ commit }, data) {
      return RegistrationAssessorApi.resendAssessorOtp(data);
    },
    /** AssessorTCRegistration
     * @param commit { Object} fields
     * @param data { Object} fields
     * @returns {Promise<unknown>}
     */
    async assessorTCRegistration ({ commit }, data) {
      return await RegistrationAssessorApi.assessorTCRegistration(data);
    },
    /** validateAssessorTCRegistration
     * @param type { Object} commit
     * @param data { Object} fields
     * @returns {Promise<unknown>}
     */
    async validateAssessorTCRegistration ({ commit }, data) {
      return await RegistrationAssessorApi.validateAssessorTCRegistration(data);
    },
    /** resendAssessorTCOtp
     * @param {type Object} fields
     * @returns {Promise<unknown>}
     */
    resendAssessorTCOtp ({ commit }, data) {
      return RegistrationAssessorApi.resendAssessorTCOtp(data);
    }
  }
};

export default module;
