<template>
  <div>
    <div
      v-if="useNewView"
      class="svp-lang-switcher"
      data-test-id="changeLanguage"
      tabindex="0"
      @click="changeLanguage"
    >
      <span>{{ isLangEng ? 'العربية' : 'English' }}</span>
      <icon-lang-switcher
        height="24px"
        width="24px"
      />
    </div>
    <div
      v-else
      class="svp-lang-switcher-old"
      data-test-id="changeLanguage"
      tabindex="0"
      @click="changeLanguage"
    >
      <span>{{ isLangEng ? 'العربية' : 'English' }}</span>
      <icon-lang-switcher
        height="24px"
        width="24px"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import IconLangSwitcher from '@/app/shared/components/appShell/icons/IconLangSwitcher.vue';

export default {
  name: 'LanguageSwitcher',
  components: { IconLangSwitcher },
  props: {
    useNewView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({ isLangEng: 'isLangEng' })
  },
  methods: {
    ...mapActions(['SET_LANG']),
    changeLanguage () {
      this.isLangEng ? this.SET_LANG('ar') : this.SET_LANG('en');
      this.$emit('languageChanged');
    }
  }
};
</script>

<style lang="scss">
.svp-lang-switcher-old {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  span {
    font-size: 20px;
  }
}

.svp-lang-switcher {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  span {
    font-size: 20px;
  }

  svg {
    color: $color-black-table;
  }
}
</style>
