<template>
  <svp-layout
    :aside-is-loading="!permissionsIsFetched"
    :nav-items="navList"
  >
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import IconCase from '@/app/shared/components/appShell/icons/IconCase.vue';
import IconCreditCard from '@/app/shared/components/appShell/icons/IconCreditCard.vue';
import IconRosette from '@/app/shared/components/appShell/icons/IconRosette.vue';
import IconTransaction from '@/app/shared/components/appShell/icons/IconTransaction.vue';
import IconUser from '@/app/shared/components/appShell/icons/IconUser.vue';
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { getPermittedPath } from '@/services/userPermissions';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'Layout',
  components: {
    SvpLayout
  },
  data () {
    return {
      pageTimeOut: 0,
      navItems: []
    };
  },
  computed: {
    ...mapGetters([
      'getIsLegislator',
      'getIsTestCenterOwner',
      'permissionsObject', // ??
      'permissionsLabors',
      'permissionsPayment',
      'permissionsTestCenters',
      'permissionsIsFetched'
    ]),
    isHideSessionsReportForLegislatorActiveFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_SESSIONS_AND_RESERVATIONS);
    },
    showAssessorsPageForTestCenterFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_VIEW_ASSESSORS_LIST);
    },
    showAssessorsPageForLegislatorFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_ASSESSORS_LIST);
    },
    tcStartExamFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_START_EXAM);
    },
    navList () {
      const navItems = [];
      const addNavItem = (icon, descriptionPath, routeName) => {
        navItems.push({ icon, descriptionPath, routeName });
      };
      if (this.permissionsLabors.view) {
        addNavItem(IconUser, 'nav.labors', 'LaborsPage');
      }
      if (this.permissionsPayment.make_payment) {
        addNavItem(IconCreditCard, 'nav.payment', 'PaymentTablePage');
      }
      if (this.permissionsTestCenters.view) {
        addNavItem(IconCase, 'nav.testCenters', 'TestCentersList');
      }
      if (this.permissionsPayment.view_transaction_history) {
        addNavItem(IconTransaction, 'nav.transactionHistory', 'TransactionHistoryPage');
        addNavItem(IconTransaction, 'nav.reports', 'Reports');
      }
      if (this.permissionsLabors.view && this.permissionsLabors.upload_results && this.tcStartExamFF) {
        addNavItem(IconRosette, 'nav.startExam', 'StartExam');
      }
      return navItems;
    }
  },
  watch: {
    permissionsObject (val) {
      this.fnWrapperWithLoader(this.redirectBasedOnPermissions, val);
    }
  },
  async created () {
    try {
      await this.getCertificatePrice();
      this.getIsLegislator && await this.getAccountInfo(); // This is needed for the UserInfo component to work.
    } catch (error) {
      console.log(error.response);
    }
  },
  methods: {
    ...mapActions(['SET_PAGE_LOADING', 'getCertificatePrice', 'getAccountInfo']),
    /**
     * @param {Function} cbFunction callback function
     * @param arguments You can pass arguments after 'cbFunction'
     * call example: fnWrapperWithLoader(cbFunction, obj, arr, str)
     */

    // why all this logic exist, what reason?
    fnWrapperWithLoader (cbFunction) {
      const args = Array.prototype.splice.call(arguments, 1);

      try {
        clearTimeout(this.pageTimeOut);

        this.SET_PAGE_LOADING(true); // Show loader

        cbFunction(...args);
      } catch (err) {
        console.error(err);
      }

      this.pageTimeOut = setTimeout(() => {
        this.SET_PAGE_LOADING(false); // Hide loader
      }, 1000);
    },

    redirectBasedOnPermissions (permissionsObject) {
      const { path, meta: { withoutPermissions } } = this.$route;

      const redirectPath = getPermittedPath(permissionsObject, path);

      // Push to permitted path
      if (redirectPath !== path && !withoutPermissions) {
        this.$router.push({ path: redirectPath }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    }
  }
};
</script>
