export const assessorTranslations = {
  en: {
    assessor: {
      duration: 'Duration',
      status: 'Status',
      view: 'View',
      testCenter: 'Test Center',
      category: 'Category',
      city: 'City',
      actions: 'Actions',
      occupation: 'Occupation',
      sessions: {
        reject: 'Reject',
        approve: 'Approve',
        withdrawal: 'Withdrawal',
        sessionNo: 'Session No.',
        sessionDate: 'Session Date',
        sessionDateAndTime: 'Session Date and Time',
        sessionDuration: 'Session Duration',
        sessionStatus: 'Session Status',
        basicInfo: 'Basic Information',
        applicants: 'Applicants',
        withdrawBtnInfoTitle: 'Sorry, withdrawing is not allowed within {time} hours of the test date. Thank you for your understanding.',
        applications: {
          applicantName: 'Applicant Name',
          passportNo: 'Passport No.',
          bookingNo: 'Booking No.',
          testResult: 'Test Result',
          bookingStatus: 'Booking Status',
          attachment: 'Attachment',
          noApplicant: 'Currently, there aren’t any applicants.',
          startTestCBT: 'Start test (CBT)',
          startPractical: 'Start practical test',
          uploadFile: 'Upload file',
          downloadFile: 'Download file',
          submitBtnText: 'Submit',
          backBtnText: 'Back',
          uploadFileInputLabel: 'The practical test form for the applicant along with photos of the applicant',
          uploadFileInputTipText: 'File number limit: 1. Single file size limit: 5 megabytes. Allowed file types: pdf.',
          fileUploadSuccessMsg: 'Applicant’s file has been submitted successfully!',
          fileSizeExceededMsg: 'The number of files should not exceed one, and each file should not exceed 5 megabytes in size. File must be in pdf format.'
        },
        cancelationDetails: 'Cancelation details',
        canceledBy: 'Canceled by',
        cancelationReason: 'Cancelation reason',
        filter: {
          filter: 'Filter',
          sessionStatus: 'Session Status',
          allStatuses: 'All statuses',
          selectAll: 'Select all',
          clearAll: 'Clear all',
          apply: 'Apply',
          drafted: 'Drafted',
          initiallyScheduled: 'Initially Scheduled',
          scheduled: 'Scheduled',
          assessorWithdrawn: 'Assessor Withdrawn',
          inProgress: 'In progress',
          canceled: 'Canceled',
          completed: 'Completed',
          expired: 'Expired'
        }
      },
      unAssignedSessions: {
        unassignedExamSessions: 'Unassigned sessions',
        startDateAndTime: 'Start Date and Time',
        backToTable: 'Back to Unassigned session page',
        occupation: 'Occupation',
        noSessions: "Currently, there aren't any sessions."
      },
      assignedSessions: {
        assignedSessions: 'Assigned sessions',
        assignedExamSessions: 'Assigned exam sessions',
        practicalResult: 'Practical result',
        dateAndTime: 'Date and Time',
        backToTable: 'Back to Assigned session page',
        noAssignedSessions: 'Currently, there aren’t any assigned sessions for you.'
      },
      assessorBasicInfo: {
        numberOfReservations: 'Number of reservations',
        session: 'Session',
        category: 'Category',
        city: 'City',
        testCenter: 'Test Center',
        dateAndTime: 'Date and Time',
        duration: 'Durations',
        fullCapacity: 'Number of Seats',
        sessionStatus: 'Session status',
        map: 'Map',
        assignedAssessor: 'Assigned assessor'
      },
      register: {
        chooseEducation: 'Choose education',
        educationalQulifications: 'What is your educational qualification?',
        professioanlQualification: 'Do you have professional qualification?',
        internationalQualification: 'Do you have an international qualification?',
        uploadQualifications: 'Upload Qualifications',
        uploadFormat: 'The file size should not exceed 3 megabytes. <br/> The allowed file formats are <strong>jpg, png, jpeg, pdf </strong> <br/> The allowed number of files is only <strong>3</strong>',
        unlearnedOption: 'Unlearned (Can not read or write)',
        learnedOption: 'Learned (Can read and write)',
        primaryOption: 'Primary (6 - 8 years)',
        middleOption: 'Middle (10 - 12 years)',
        secondaryOption: 'Secondary (12 years and more)',
        diplomaOption: 'Diploma',
        bachelorsOption: 'Bachelors degree or more',
        noneOption: 'None',
        trainingOption: 'Short course / Training',
        degreeOption: 'Degree',
        requiredField: 'This field is required',
        fileUploadError: 'The number of files should not exceed 3, with each file not exceeding 3 megabytes in size, and they should be in one of the following formats: jpg, png, jpeg, pdf.',
        internationalQualifyDiploma: 'Diploma'
      }
    }
  },
  ar: {
    assessor: {
      duration: 'المدة',
      status: 'الحالة',
      view: 'عرض',
      testCenter: 'مركز الاختبار',
      category: 'المهنة',
      city: 'المدينة',
      actions: 'الإجراءات',
      occupation: 'المهنة',
      sessions: {
        approve: 'قبول',
        reject: 'رفض',
        withdrawal: 'انسحاب',
        sessionNo: 'رقم الجلسة',
        sessionDate: 'تاريخ الجلسة',
        sessionDateAndTime: 'وقت وتاريخ الجلسة',
        sessionDuration: 'مدة الجلسة',
        sessionStatus: 'حالة الجلسة',
        basicInfo: 'المعلومات الأساسية',
        applicants: 'المُتقدمين',
        withdrawBtnInfoTitle: 'عذرًا، لا يُسمح بالانسحاب من الجلسة خلال {time} ساعة من تاريخ الاختبار. شكرًا لتفهمك.',
        applications: {
          applicantName: 'اسم المُتقدم',
          passportNo: 'رقم الجواز',
          bookingNo: 'رقم الحجز',
          testResult: 'نتيجة الاختبار',
          bookingStatus: 'حالة الحجز',
          attachment: 'المرفقات',
          noApplicant: 'حاليًا، لا يوجد أي متقدمين',
          startTestCBT: 'ابدأ الاختبار النظري',
          startPractical: 'ابدأ الاختبار العملي',
          uploadFile: 'رفع الملف',
          submitBtnText: 'رفع',
          backBtnText: 'عودة',
          downloadFile: 'تحميل الملف',
          uploadFileInputLabel: 'نموذج الاختبار العملي للمتقدم بالإضافة إلى صور المتقدم',
          uploadFileInputTipText: 'عدد الملفات المتاح رفعها: 1 حجم الملف الأقصى: 5 ميجابايت الملفات المتاحة: PDF',
          fileUploadSuccessMsg: 'تم إرسال ملف المُتقدم بنجاح!',
          fileSizeExceededMsg: 'يجب ألا يتجاوز عدد الملفات ملفًا واحدًا، ويجب ألا يتجاوز حجم الملف 5 ميجابايت، وأن يكون الملف بصيغة pdf.'
        },
        cancelationDetails: 'تفاصيل الإلغاء',
        canceledBy: 'ألغيت من قبل',
        cancelationReason: 'سبب الإلغاء',
        filter: {
          filter: 'تصفية',
          sessionStatus: 'حالة الجلسة',
          allStatuses: 'كل الحالات',
          selectAll: 'تحديد الكل',
          clearAll: 'إلغاء الكل',
          apply: 'تطبيق',
          drafted: 'مسودة',
          initiallyScheduled: 'مجدولة مبدئيًّا',
          scheduled: 'مجدولة',
          assessorWithdrawn: 'مقيم منسحب',
          inProgress: 'قيد التنفيذ',
          canceled: 'ملغية',
          completed: 'مكتملة',
          expired: 'منتهية'
        }
      },
      unAssignedSessions: {
        unassignedExamSessions: 'الجلسات الغير مسندة',
        startDateAndTime: 'الوقت والتاريخ',
        occupation: 'المهنة',
        backToTable: 'العودة للجلسات الغير مسندة',
        noSessions: 'حاليًا، لا توجد أي جلسات'
      },
      assignedSessions: {
        assignedSessions: 'الجلسات المسندة',
        assignedExamSessions: 'جلسات الاختبار',
        practicalResult: 'نتيجة الاختبار العملي',
        dateAndTime: 'التاريخ و الوقت',
        backToTable: 'العودة للجلسات المسندة',
        noAssignedSessions: 'حاليًا، لا توجد أي جلسات مسندة لك.'
      },
      assessorBasicInfo: {
        numberOfReservations: 'عدد الحجوزات',
        session: 'الجلسة',
        category: 'الفئة',
        city: 'المدينة',
        testCenter: 'مركز الاختبار',
        dateAndTime: 'التاريخ و الوقت',
        duration: 'مدة',
        fullCapacity: 'عدد المقاعد',
        sessionStatus: 'الحالة',
        map: 'الخريطة',
        assignedAssessor: 'المقيّم المكلّف'
      },
      register: {
        chooseEducation: 'اختر التعليم',
        educationalQulifications: 'ما هو مؤهلك التعليمي؟',
        professioanlQualification: 'هل عندك مؤهل مهني؟',
        internationalQualification: 'هل عندك مؤهل دولي؟',
        uploadQualifications: 'أرفق مؤهلك التعليمي',
        uploadFormat: 'حجم الملف يجيب ألا يتجاوز 3 ميجابايت. <br/> صيغة الملف المسموح به: <strong>jpg, png, jpeg, pdf</strong> <br/> عدد الملفات المسموح بها هي<strong>3</strong> ملفات فقط.',
        unlearnedOption: 'أمي /غير متعلم (غير قادر على القراءة أو الكتابة)',
        learnedOption: 'متعلم (قادر على القراءة والكتابة)',
        primaryOption: 'الابتدائية (التأسيسية)',
        middleOption: 'المتوسطة (الإعدادية)',
        secondaryOption: 'الثانوية',
        diplomaOption: 'دبلوم',
        bachelorsOption: 'بكالوريوس فأكثر',
        noneOption: 'لايوجد',
        trainingOption: 'دورة تدريب',
        degreeOption: 'الدرجة العلمية',
        requiredField: 'حقل إجباري',
        fileUploadError: 'يجب أن تكون عدد الملفات لا تتجاوز 3 ملفات ويكون حجم كل ملف لا يتجاوز 3 ميجابايت ويكون بأحد الصيغ التالية: jpg, png, jpeg, pdf.',
        internationalQualifyDiploma: 'شهادة الدبلوم'
      }
    }
  }
};
