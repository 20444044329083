export const authTranslations = {
  en: {
    auth: {
      weSentTo: 'We just sent you confirmation code to',
      confirmationCode: 'Confirmation code',
      signIn: 'Sign in',
      verifyCode: 'Verify code',
      providePassword: 'Enter your password',
      password: 'Password',
      existingAccount: 'Already have an account?',
      continue: 'Continue',
      editEmail: 'Edit Email',
      editPhone: 'Edit phone number',
      info: 'Info',
      fileErrorValidation: 'Your photo must be in PNG, JPG or JPEG format, not exceeding 2 megabytes.',
      recaptchaIsNotSolved: 'Recaptcha is not solved',
      login: {
        welcome: 'Welcome back',
        signIn: 'Sign in',
        signUp: 'Sign up',
        provideEmail: 'Enter your email',
        createAnAccount: 'Don\'t have an account?',
        phoneNumber: 'Phone Number',
        email: 'Email',
        forgot: 'Forgot password?',
        rememberMe: 'Remember me',
        changeEmail: 'Change email',
        changePhoneNumber: 'Change phone number',
        pleaseEnterEmail: 'please enter it below or use another email for login',
        pleaseEnterPhone: 'please enter it below or use another phone number for login',
        pleaseEnterVerificationCode: 'Please enter the verification code sent to',
        verificationCode: 'Verification Code'
      },
      activateAccount: {
        title: 'Activate your account',
        confPass: 'Confirm Password',
        reminder: 'Important Information',
        createNewPass: 'Create your password to activate your account',
        passwordNotMatch: 'Password do not match',
        accountHasBeenActivated: 'Your account has been activated successfully!',
        passwordsNotMatch: 'Passwords do not match'
      },
      passwordStrength: {
        length: 'Minimum 8 Characters',
        upper: 'Uppercase letter',
        lower: 'Lowercase letter',
        number: 'Number',
        symbol: 'Special character'
      },
      resetPassword: {
        heading: 'Reset password',
        enterYourPhoneNumber: 'Enter your phone number',
        countryCode: 'Country code',
        phoneNumber: 'Phone Number',
        backToSignIn: 'Back to Sign in',
        checkYourNumber: 'Check your phone number',
        numberIsRegistered: 'If this phone number <span class="text-bold"> +{number} </span> is registered, we\'ll send you an SMS with a verification code.',
        verificationCode: 'Verification Code',
        checkYourEmail: 'Check your email',
        emailIsRegistered: 'If this email <span class="text-bold"> {email} </span> is registered, we\'ll send you a password reset email.',
        ifEmailDoesntArrive: 'If it doesn’t arrive soon, check your spam folder or',
        sendEmailAgain: 'Send the email again',
        changeEmail: 'Change email',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        setPassword: 'Set password',
        wrongCode: 'Wrong code provided. Try again',
        invalidVerificationCode: 'Invalid verification code.',
        passwordIsUpdated: 'Your password has been updated successfully!',
        defaultResetPassErrorLabel: 'An error has occurred. Please try again.'
      },
      register: {
        heading: 'Create your account',
        assessorHeading: 'Register into SVPI!',
        important: 'Important',
        notificationDesc: '<span class="text-bold">Once the account is created, data cannot be modified or changed.</span> Please ensure accurate information is entered for all fields in this form.',
        infoAsPassport: 'Please enter your information exactly as it is written in your passport',
        infoAsNationalId: 'Please enter your information exactly as it is written in your national ID',
        thankYouForReg: 'Thank you for registering!',
        stepper: {
          one: 'Passport <br/> Information',
          two: 'Other <br/> Details',
          three: 'Contact <br/> Information',
          four: 'Account <br/> Verification'
        },
        backToPassport: 'Back to Passport information',
        backToOtherDetails: 'Back to Other details',
        backToContactInformation: 'Back to Contact information',
        backToQualificationsDetails: 'Back to Qualifications Details',
        completeRegistration: 'Complete registration',
        registerSuccessTitle: 'Success!',
        registerSuccessBtnText: 'Ok',
        registerSuccessMsg: 'Your registration request is received and is currently under the legislator’s review.',
        registerSuccessNotification: 'We will inform you of the result via email and SMS.',
        instructionsModal: {
          general: 'General',
          color: 'Color',
          quality: 'Quality',
          scan: 'Scan',
          cropping: 'Cropping',
          uploadBtn: 'Upload file',
          upload: 'Upload',
          heading: 'Instructions for uploading Passport',
          cnicHeading: 'Instructions for uploading National ID card',
          only: 'Only <span class="text-bold">PNG, JPEG</span> or <span class="text-bold">JPG</span> images must be used',
          size: 'The <span class="text-bold">size</span> of the photo should not  exceed <span class="text-bold">2  MBs</span>',
          name: 'If your <span class="text-bold">last name is blank</span>, please provide <span class="text-bold">your father\'s name</span> as the last name',
          code: 'The <span class="text-bold">MRZ code</span> should be <span class="text-bold">clearly visible</span> otherwise the request will be rejected',
          valid: 'The <span class="text-bold">MRZ is 2 or 3 lines of letters, symbols, and numbers</span>, at the bottom of the personal details page on your passport',
          fullColor: 'Please make sure to upload the document <span class="text-bold">in full color</span>.',
          noGlare: '<span class="text-bold">No glare</span> or stain over the scan.',
          noShadows: '<span class="text-bold">No shadows</span> over the scan.',
          onlySinglePage: 'Double pages of scanned copies are not allowed. <br/> <span class="text-bold">Only a single page</span> should be uploaded.',
          fullInfoReq: 'Crop the document so that <span class="text-bold">no information is missed</span>',
          ensureDocumentIsValid: 'Ensure your <span class="text-bold">document is valid</span> and not expired',
          flatSurface: 'Place your document on a <span class="text-bold">flat surface in a well-lit area</span>',
          allPartsAreVisible: 'Make sure <span class="text-bold">all parts</span> of the front and back of the national ID card <span class="text-bold">are visible</span> in the photos',
          clearPhoto: 'Photos <span class="text-bold">must be clear</span>, without glare or reflections',
          alreadyReviewed: 'I have reviewed the instructions on how to upload the photo.',
          uploadYourPassport: 'Upload your passport',
          uploadYourCnicFront: 'Upload Front side of your national ID card',
          uploadYourCnicBack: 'Upload Back side of your national ID card',
          uploadYourPhoto: 'Upload your photo',
          uploadFront: 'Upload Front',
          uploadBack: 'Upload Back',
          systemNoPassport: 'We could not read your passport from this photo',
          followInstructions: 'Please make sure:',
          machineReadable: 'The machine-readable zone <strong>(MRZ)</strong> is <strong>clear and in focus.</strong>',
          entirePassport: 'The <strong>entire passport is visible</strong> in the photo.',
          cropDocument: 'It is not <strong>too cropped or zoomed in.</strong>',
          yourPassport: 'The photo is of <strong>your passport.</strong>',
          yourImageUploaded: '[The image of your passport that you uploaded in the previous step]',
          showDetailsClearly: 'The photo must show passport details clearly, otherwise your test will be rejected.',
          photoMeetsRequirements: 'If you think this photo meets the requirements, you can continue with this photo. You will need to provide your passport details manually.',
          provideManually: 'Provide manually',
          reuploadPassport: 'Reupload Passport',
          passportPhoneProcessing: 'Passport photo is under processing',
          pleaseWait: 'Please wait. Do not close the page'
        },
        passportConfirmModal: {
          heading: 'Passport Confirmation',
          cnicHeading: 'National ID Card Confirmation',
          isCorrectPassportInfo: 'Is your passport information correct?',
          isCorrectInfo: 'Is your information correct?',
          infoBlockPassport: 'The photo of your passport that you uploaded in the previous step.',
          infoBlockNationalId: 'The photo of your national ID that you uploaded in the previous step:',
          verifyCheckboxPassport: 'Please verify the passport information above, as it cannot be modified after registration.',
          verifyCheckbox: 'Please verify the information above, as it cannot be modified after registration.'
        },
        photoModal: {
          title: 'Personal Photo Standard Requirements',
          pose: 'Pose',
          glasses: 'Glasses',
          headdress: 'Headdress',
          must: 'The photo must <span class="text-bold">both show a close up of your face and the top of the shoulders.</span>',
          takeUp: 'Your <span class="text-bold">face</span> must take up <span class="text-bold">70%</span> to <span class="text-bold">80%</span> of the photo',
          size: 'The size of the photo should not be more than <span class="text-bold">2 MBs</span>',
          format: 'Only <span class="text-bold">PNG, JPEG</span> or <span class="text-bold">JPG</span> images must be used',
          dimensions: 'Photo must be in dimension of <span class="text-bold">40 (height) x 30 (width)</span>. Minimum resolution of <span class="text-bold">720px</span>',
          less: 'Your photo must be taken <span class="text-bold">less than 6 months</span> ago',
          colored: '<span class="text-bold">Colored</span>',
          background: 'Taken against a <span class="text-bold">plain white or light grey background</span>',
          clear: '<span class="text-bold">Clear</span>, have <span class="text-bold">high resolution</span> and with <span class="text-bold">balanced light</span>',
          colorBalance: 'Have a <span class="text-bold">good color balance</span>, natural flesh tones and without \'red eye\'',
          contrast: 'Have enough <span class="text-bold">contrast between your facial</span> features <span class="text-bold">and the background</span>',
          shadows: '<span class="text-bold">No shadows or glare</span> in the Photo or in the background',
          position: '<span class="text-bold">Your face must be centered in the image.</span> <br/> You must look directly at the camera and show your full face.',
          head: '<span class="text-bold">Do not tilt or turn your head in any way</span>',
          expression: '<span class="text-bold">Your expression must be neutral.</span> <br/> Do not squint, frown, smile or use any other expression.',
          eye: '<span class="text-bold">Your eyes must be open, and your mouth closed.</span> <br/> There must be no hair covering your eyes.',
          glass: 'Do <span class="text-bold">not wear sunglasses or tinted/ colored glasses.</span>',
          visible: 'If you normally wear glasses, your eyes must be clearly visible in the photo.',
          notWearHeaddress: 'Do <span class="text-bold">not wear a headdress</span>, except if you wear it for religious reasons.',
          clearlyVisible: 'If you wear a <span class="text-bold">head cover for religious reasons</span>, your <span class="text-bold">face</span> must be <span class="text-bold">clearly visible.</span>'

        },
        uploadPassport: {
          heading: 'Upload Passport',
          nationalIdHeading: 'Upload National ID',
          reUploadPassport: 'Reupload passport',
          reUploadNationalId: 'Reupload National ID',
          passportInformative: 'Please upload or take a photo of your <span class="text-bold">passport</span>',
          nationalIdInformative: 'Please upload or take a photo of the front and back of your <span class="text-bold">national ID</span> card.',
          uploadFormat: 'Your photo must be in <span class="text-bold">PNG, JPG</span> or <span class="text-bold">JPEG</span> format, not exceeding 2 megabytes.',
          givenNames: 'First Name (Given Names)',
          enterName: 'Enter your first name',
          surname: 'Last Name (Surname)',
          enterSurname: 'Enter your last name',
          passportNumber: 'Passport No.',
          enterPassword: 'Enter your password number',
          country: 'Country',
          countryOfResidence: 'Country of residence',
          chooseCountry: 'Choose country',
          nationality: 'Nationality',
          chooseNationality: 'Choose nationality',
          dateOfBirth: 'Date of Birth',
          datePlaceholder: 'Date',
          yearPlaceholder: 'Year',
          sex: 'Sex',
          male: 'Male',
          female: 'Female',
          passportExpiry: 'Date of Passport Expiry',
          nationalIdExpiry: 'Date of Expiry',
          selectDatePlaceholder: 'DD/MM/YYYY',
          passportTIP: 'Make sure you’re entering the passport number, not the serial number.',
          months: {
            jan: 'January',
            feb: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December'
          }
        },
        otherDetails: {
          uploadHeading: 'Upload Your Personal Photo',
          informative: 'Please upload or take a personal photo showing <span class="text-bold">your full face.</span>',
          uploadBtn: 'Upload photo',
          reUploadBtn: 'Reupload photo',
          nationalId: 'National ID',
          nationalIdPlaceholder: 'Enter your national ID',
          nationalIdTip: 'Enter your National ID as it is written on your identity card.',
          educationLvl: 'Education Level',
          educationLvlPlaceholder: 'Choose education',
          chooseExp: 'Choose experience',
          education: {
            lvlOne: 'Unlearned (Can not read or write)',
            lvlTwo: 'Learned (Can read and write)',
            lvlThree: 'Primary (6 – 8 years)',
            lvlFour: 'Middle (10 - 12 years)',
            lvlFive: 'Secondary (12 years and more)',
            lvlSix: 'Diploma or more'
          },
          knowledgeLvl: 'Do you have the knowledge and skills required for the occupation you intend to apply for?',
          knowledgeLvlPlaceholder: 'Choose knowledge level',
          knowledge: {
            lvlOne: 'Yes',
            lvlTwo: 'Yes, and I have experience.',
            lvlThree: 'Yes, and I have certificate(s), course(s), or training.'
          },
          experienceLvl: {
            label: 'Experience Level',
            lvlOne: 'Less than 3 years',
            lvlTwo: '3 - 5 years',
            lvlThree: 'More than 5 years'
          },
          instituteLvl: {
            label: 'Choose the institutes from which you obtained certificates, courses, or training',
            placeholder: 'Choose institute',
            lvlOne: 'Other',
            lvlTwo: 'Ministry of Labour in Egypt',
            lvlThree: 'National Skill Development Corporation (NSDC)',
            lvlFour: 'National Vocational & Technical Training Commission (NAVTTC)',
            lvlFive: 'Bureau of Manpower, Employment and Training (BMET)',
            lvlSix: 'Tertiary and Vocational Education Commission (TVEC)'
          },
          instituteName: 'Institute Name',
          institutePlaceholder: 'Enter institute name',
          allDataIsValid: 'I acknowledge that all the entered data is correct and is my responsibility'
        },
        contactInfo: {
          infoDescription: 'Enter your phone number or email, which will be used for logging into the platform.',
          verifyMethod: 'Preferable verification method'
        },
        accountVerification: {
          emailTitle: 'Email verification',
          phoneTitle: 'Phone verification',
          phoneTitleOptional: 'Phone verification (optional)',
          sendCode: 'We just sent your verification code to',
          pleaseEnter: 'Please enter it below or',
          changePhone: 'change phone',
          changeEmail: 'change email',
          emailHasBeenVerified: 'You have successfully verified your email',
          phoneHasBeenVerified: 'You have successfully verified your phone',
          sendCodeToEmail: 'We just sent your verification code to'
        },
        leaveModal: {
          stopProcess: 'Are you sure you want to stop the registration process?',
          assessorRefLink: "You've started the registration process via the invitation link. If you leave this page, your registration will not be completed.",
          userSure: "Yes, I'm sure",
          userNo: 'No'
        }
      },
      changePassword: {
        done: 'Done',
        changePassword: 'Change password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        successUpdated: 'Your password has been updated successfully!'
      },
      signUp: {
        signUp: 'Sign Up',
        registerIntoSVP: 'Register into SVP',
        fullName: 'Full name',
        firstName: 'First name',
        lastName: 'Last name',
        newPassword: 'Password',
        confirmNewPassword: 'Confirm password',
        nationalId: 'National ID',
        cnic: 'ID No.',
        enterNationalId: 'Enter your ID No.',
        enterNationalIdHint: 'Please enter your government-issued ID No. (e.g., National ID, CNIC, SSN...etc.)',
        passportNumber: 'Passport number',
        phoneNumber: 'Phone number',
        countryOfResidence: 'Country of residence',
        nationality: 'Nationality',
        chooseCountry: 'Choose country',
        chooseNationality: 'Choose nationality',
        enterYourName: 'Enter your name',
        enterYourFirstName: 'Enter your first name',
        enterYourLastName: 'Enter your last name',
        enterYourNationalId: 'Enter your national ID',
        enterYourPassportNumber: 'Enter your passport number',
        enterYourEmail: 'Enter your email',
        enterNewPassword: 'Enter password',
        email: 'Email',
        dateOfBirth: 'Date of birth',
        dateOfBirthPlaceholder: 'Enter your birth date',
        passportExpiration: 'Passport expiration',
        passportExpirationPlaceholder: 'Enter passport expiration',
        checkboxText: 'I acknowledge that all the data entered are correct and under my responsibility',
        dontHaveEmail: 'I don’t have email',
        description: '<b>Data cannot be amended or changed after creating the account</b>, make sure you’re entering correct data for all fields in this form',
        phoneNumberError: 'This phone number is already in use with another account',
        backToRegistration: 'Back to registration',
        phoneNumberErrorLocal: 'Invalid phone number',
        tooltip: {
          firstName: 'Enter your first name as shown in the passport',
          lastName: 'Enter your last name as shown in the passport',
          nationalId: 'Enter your National ID as shown in Identity card',
          passportNumber: 'Enter your correct Passport number',
          countryOfResidence: 'Please choose your current Country of residence',
          nationality: 'Select your nationality as shown in the passport',
          phoneNumber: 'Please enter your phone number that will be used in OTP verification',
          email: 'Please enter a valid Email that will be used in your login into the portal',
          newPassword: 'New password',
          confirmNewPassword: 'Confirm new password',
          passportNumberHint: 'Make sure you’re entering <b>passport number</b> not <b>serial number</b>.'
        },
        welcomeIntoSVP: 'Welcome to International SVP!',
        fillInCode: 'Fill the field with the auth code sent to the registered phone number in SVP',
        enterCode: 'Enter Code',
        fillOutAllFields: 'Please fill out all required fields',
        mustExist: 'must exist',
        cantBeBlank: 'can’t be blank',
        fieldNames: {
          country: 'Country',
          national_id: 'National ID',
          nationality_id: 'Nationality',
          passport_number: 'Passport number',
          full_name: 'Full name',
          email: 'Email',
          password: 'Password',
          password_confirmation: 'Password confirmation',
          date_of_birth: 'Date of birth',
          passport_expiration_date: 'Passport expiration'
        }
      },
      forgotPassword: {
        heading: 'Reset password',
        email: 'Please enter your email',
        email_error: 'Invalid Email',
        send_btn: 'Send',
        continue_btn: 'Continue',
        sign_in_text: 'To sign in',
        sign_in_link: 'sign in',
        submit_form_text: 'If you’re registered in the system, you will receive a password recovery link at your email address in a few minutes.',
        emailBtnText: 'Email',
        phoneNumberBtnText: 'Phone number',
        passwordResetInstructions: 'We’ve sent password reset instructions to',
        checkSpam: 'If it doesn’t arrive soon, check your spam folder or',
        sendEmailAgain: 'Send the email again',
        backToSignIn: 'Back to Sign In',
        inputPhoneNumber: 'Input phone number registered to SVPI',
        smsWasSend: 'We\'ve sent SMS with a confirmation code to:',
        phoneNumberErrorLocal: 'Invalid phone number',
        checkYourEmail: 'Check your email'
      },
      registrationAssessorForm: {
        fullName: 'Full name',
        fullNamePlaceholder: 'Full name, no abbreviation',
        dateOfBirth: 'Date of Birth',
        dateOfBirthPlaceholder: 'Enter your birth date',
        gender: 'Gender',
        genderPlaceholder: 'Enter your gender',
        nationality: 'Nationality',
        nationalityPlaceholder: 'Enter your nationality',
        nationalId: 'CNIC (National ID)',
        nationalIdPlaceholder: 'Enter your CNIC',
        passportNumber: 'Passport Number',
        passportNumberPlaceholder: 'Enter your Passport number',
        phoneNumber: 'Phone',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        passportUpload: 'Upload passport',
        passportUploadTitle: 'Attach your passport copy or photo',
        instituteName: 'Institute name'
      },
      scopeOfWork: {
        country: 'Country',
        chooseCountry: 'Choose country',
        city: 'City',
        chooseCity: 'Choose city',
        cityHint: 'The chosen city will be in your scope of work'
      },
      detailsForm: {
        category: 'Category',
        chooseCategories: 'Choose categories',
        yes: 'Yes',
        no: 'No',
        none: 'None',
        educationalQualifification: 'What is your your Educational Qualification?',
        moreTen: 'More than 10 Years',
        five: '5 Years',
        three: '3 Years',
        lessThree: 'Less than 3 Years',
        moreThree: 'More than 3 Years',
        sixteenMore: '16 Years and Above',
        fourteen: '14 Years',
        more_than_3: 'More than 3 years',
        less_than_3: 'Less than 3 years',
        no_international_experience: 'No international experience',
        dae: 'Diploma of Associate Engineering',
        uploadQualifications: 'Upload qualifications',
        attachLicensesCertifications: "Attach your License's & Certifications",
        whatIsYourExperience: 'What is your Experience?',
        uploadExperienceCertificate: 'Upload Experience Certifications',
        certifiedOther: 'Are you a certified assessor from any other institute?',
        whichLanguage: 'Which Languages you know?',
        chooseLanguages: 'Choose languages',
        englishMandatory: 'Note: English is mandatory',
        itSkills: 'Basic IT Skills',
        diplomaInIt: 'Diploma or more in IT',
        certificateInIt: 'Certificate in IT',
        msOffice: 'MS Office, or Internet',
        onlineTools: 'Having Knowledge and Skill to handle online tools teams/ zoom etc?',
        profQualification: 'Any Professional Qualification?',
        internalQualification: 'Any International Qualification?',
        degree: 'Degree',
        diploma: 'Diploma',
        training: 'Short course/ Training',
        internalExperience: 'Have you worked outside your country before?',
        submitRequest: 'Submit Request',
        termsAndConditions: 'Terms & Conditions',
        agree: 'I agree with',
        success: 'Success',
        successText: 'Your registration request is received and is currently under the legislator’s review. We will inform you of the result via email and SMS.',
        experienceCertificateHintText: 'Experience certificates, recommendations, or clearances',
        instituteLvlLabel: 'Choose the institutes',
        instituteLvlPlaceholder: 'Choose institute',
        lessThanThreeYears: '2-3 or less than 3 years.',
        moreThanThreeYears: 'More than 3 years.',
        skillsOfOnlineTools: 'Do you have the knowledge and skills to use online tools such as Zoom /Teams and others?'
      },
      assessorRegistration: {
        partnership: 'Partnership',
        registerAsAssessor: 'Register as Assessor',
        registerIntoSVP: 'Register into SVP',
        subtitle: 'Fill in your info below if you want to join Skill Verification Program as an Assessor',
        terms: 'terms and conditions applies',
        infoDescription: 'Fill in your info below if you want to join SVPI as an <b>Assessor</b>',
        documentTypeSwitchTitle: 'Choose the document type for registration',
        backToBasicInformation: 'Back to Basic Information',
        backToScopeOfWork: 'Back to Scope of work',
        stepper: {
          one: 'Basic <br/> Information',
          two: 'Scope <br /> of Work',
          three: 'Contact <br/> Information',
          four: 'Account <br/> Verification',
          five: 'Qualifications <br/> Details',
          six: 'Experiences <br/> And Skills'
        },
        passport: 'Passport',
        nationalId: 'National Id'
      }
    }
  },
  ar: {
    auth: {
      weSentTo: 'تم ارسال رمز التفعيل على البريد',
      confirmationCode: 'تأكيد الرمز',
      signIn: 'تسجيل الدخول',
      verifyCode: 'تحقق من الرمز',
      providePassword: 'أدخل كلمة المرور',
      password: 'كلمة المرور',
      existingAccount: 'لديك حساب؟',
      continue: 'استمرار',
      editEmail: 'تعديل البريد الالكتروني',
      editPhone: 'تعديل رقم الجوال',
      info: 'للإحاطة',
      fileErrorValidation: 'يجب أن تكون الصورة بصيغة PNG, JPG أو JPEG وألا تتجاوز حجم 2 ميجابايت.',
      recaptchaIsNotSolved: 'Recaptcha is not solved',
      login: {
        signIn: 'تسجيل الدخول',
        signUp: 'سجل الآن',
        welcome: 'مرحبًا بعودتك!',
        provideEmail: 'أدخل بريدك الإلكتروني',
        createAnAccount: 'ليس لديك حساب؟',
        phoneNumber: 'رقم الجوال',
        email: 'البريد الإلكتروني',
        forgot: 'نسيت كلمة المرور؟',
        rememberMe: 'تذكرني',
        changeEmail: 'تغيير البريد الإلكتروني',
        changePhoneNumber: 'تغيير رقم الجوال',
        pleaseEnterEmail: 'الرجاء إدخاله بالأسفل أو استخدام بريد إلكتروني آخر لتسجيل الدخول',
        pleaseEnterPhone: 'الرجاء إدخاله بالأسفل أو استخدام رقم جوال آخر لتسجيل الدخول',
        pleaseEnterVerificationCode: 'يرجى إدخال رمز التحقق المرسل إلى',
        verificationCode: 'رمز التحقق'

      },
      activateAccount: {
        title: 'نشّط حسابك',
        confPass: 'تأكيد كلمة المرور',
        reminder: 'معلومات مهمة',
        createNewPass: 'أنشئ كلمة المرور لتفعيل حسابك',
        passwordNotMatch: 'كلمة المرور لا تتطابق',
        accountHasBeenActivated: 'تم تنشيط حسابك بنجاح!',
        passwordsNotMatch: 'كلمة المرور لا تتطابق'
      },
      passwordStrength: {
        length: '8 خانات كحد أدنى',
        upper: 'احرف كبيرة',
        lower: 'احرف صغيرة',
        number: 'ارقام',
        symbol: 'رموز'
      },
      resetPassword: {
        heading: 'إعادة تعيين كلمة المرور',
        enterYourPhoneNumber: 'أدخل رقم جوالك',
        countryCode: 'رمز الدولة',
        phoneNumber: 'رقم الجوال',
        backToSignIn: 'العودة إلى تسجيل الدخول',
        checkYourNumber: 'تحقق من رقم جوالك',
        numberIsRegistered: 'إذا كان هذا الرقم <span class="text-bold"> {number}+ </span> مُسجَّلًا، سنُرسِل لك رسالة نصية قصيرة تحتوي على رمز التحقق.',
        verificationCode: 'رمز التحقق',
        checkYourEmail: 'تحقق من بريدك الإلكتروني',
        emailIsRegistered: 'إذا كان هذا البريد الإلكتروني <span class="text-bold"> {email} </span> مُسجَّلًا، سنُرسِل لك رسالة بريد إلكتروني لإعادة تعيين كلمة المرور.',
        ifEmailDoesntArrive: 'إذا لم تستلمها قريبًا، فتحقق من مجلد البريد العشوائي أو',
        sendEmailAgain: 'أرسل البريد الإلكتروني مرة أخرى',
        changeEmail: 'تغيير البريد الإلكتروني',
        newPassword: 'كلمة المرور الجديدة',
        confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        setPassword: 'تعيين كلمة المرور',
        wrongCode: 'حدث خطأ. حاول مرة أخرى',
        invalidVerificationCode: 'رمز التحقق الذي أدخلته غير صحيح.',
        passwordIsUpdated: 'تم تحديث كلمة المرور بنجاح!',
        defaultResetPassErrorLabel: 'حدث خطأ، يرجى المحاولة مرة أخرى.'
      },
      register: {
        heading: 'إنشاء حسابك',
        assessorHeading: 'التسجيل في منصة الفحص المهني الخارجي!',
        important: 'مهم',
        notificationDesc: '<span class="text-bold">بمجرد إنشاء الحساب، لا يمكن تعديل أو تغيير البيانات.</span> يُرجى التحقق من صحة المعلومات المٌدخلة.',
        infoAsPassport: 'يُرجى إدخال معلوماتك بالضبط كما هي مكتوبة في جواز سفرك',
        infoAsNationalId: 'يُرجى إدخال معلوماتك بالضبط كما هي مكتوبة في هويتك الوطنية',
        thankYouForReg: 'شكرًا لتسجيلك!',
        stepper: {
          one: 'معلومات <br/> جواز السفر',
          two: 'تفاصيل <br/> أخرى',
          three: 'معلومات <br/> التواصل',
          four: 'تفعيل <br/> الحساب '
        },
        backToPassport: 'رجوع إلى معلومات جواز السفر',
        backToOtherDetails: 'رجوع إلى تفاصيل أخرى',
        backToContactInformation: 'رجوع إلى معلومات التواصل',
        backToQualificationsDetails: 'العودة لتفاصيل المؤهلات',
        completeRegistration: 'إكمال التسجيل',
        registerSuccessTitle: 'تم ارسال الطلب بنجاح!',
        registerSuccessBtnText: 'تم',
        registerSuccessMsg: 'تم استلام طلب التسجيل الخاص بك وهو حاليًا قيد المراجعة من قبل المشرّع',
        registerSuccessNotification: ' سنبلغك بالنتيجة عبر البريد الإلكتروني والرسائل النصية',
        instructionsModal: {
          general: 'العامة',
          color: 'ملونة',
          quality: 'الجودة',
          scan: 'المسح',
          cropping: 'القص',
          uploadBtn: 'تحميل الملف',
          upload: 'تحميل',
          heading: 'تعليمات تحميل جواز السفر',
          cnicHeading: 'تعليمات تحميل بطاقة الهوية الوطنية',
          only: 'يرجى اختيار صور بصيغة <span class="text-bold">PNG, JPG</span> <span class="text-bold">أو<span> <span class="text-bold">JPEG</span>',
          size: 'يرجى ألا يتجاوز <span class="text-bold">حجم</span> الصورة <span class="text-bold">٢ ميجابايت</span>',
          name: 'إذا كان <span class="text-bold">اسم عائلتك فارغًا</span>، فتأكد من تقديم <span class="text-bold">اسم والدك كاسم العائلة</span>',
          code: 'يجب أن يكون رمز <span class="text-bold">MRZ</span> <span class="text-bold">مرئيّاً بوضوح</span> وإلا سيرفض الطلب.',
          valid: '<span class="text-bold">نطقة القراءة الآلية (MRZ): هي سطور مكونة من 2 أو 3 أسطر من الحروف والرموز والأرقام</span> <span>، في الجزء السفلي من صفحة تفاصيلك الشخصية في جواز السفر</span>',
          fullColor: 'يرجى تحميل جواز السفر <span class="text-bold">بالألوان الكاملة</span>',
          noGlare: 'يجب أن تكون الصورة الممسوحة خالية من <span class="text-bold">الضلال والتوهجات</span>',
          noShadows: '<span class="text-bold">لا يجب</span> أن تغطي الصورة الممسوحة ضوئياً <span class="text-bold">ظلال</span>.',
          onlySinglePage: 'لن تُقبل الصفحات المزدوجة من صورة جواز السفر، لذلك يرجى تحميل صفحة واحدة فقط.',
          fullInfoReq: 'احرص على أن تقص المستند بشكل جيد حتى لا تفوت أي معلومات.',
          ensureDocumentIsValid: 'تأكد من أن بطاقة الهوية الوطنية صالحة وليست منتهية الصلاحية',
          flatSurface: 'ضع بطاقة الهوية الوطنية على سطح مستو في منطقة مُضاءة جيدًا',
          allPartsAreVisible: 'تأكد من أن جميع أجزاء الوجه الأمامي والخلفي لبطاقة الهوية الوطنية ظاهرة في الصور.',
          clearPhoto: 'يجب أن تكون الصور واضحة وخالية من التوهج أو الانعكاسات',
          alreadyReviewed: 'لقد قرأت التعليمات لكيفية تحميل الصورة.',
          uploadYourPassport: 'تحميل جواز سفرك',
          uploadYourPhoto: 'تحميل صورتك الشخصية',
          uploadYourCnicFront: 'صورة البطاقة من الأمام',
          uploadYourCnicBack: 'صورة البطاقة من الخلف',
          uploadFront: 'تحميل الوجه الأمامي',
          uploadBack: 'تحميل الوجه الخلفي',
          systemNoPassport: 'تعذر قراءة جواز سفرك من هذه الصورة',
          followInstructions: ':يرجى التأكد مما يلي',
          machineReadable: ' .أن تكون منطقة القراءة الآلية (MRZ) واضحة وبدون تغبيش',
          entirePassport: '.تأكد من رؤية جميع أجزاء جواز السفر في الصورة',
          cropDocument: '.أن الصورة ليست مقصوصة أو مكبرة بشكل زائد',
          yourPassport: '.ن الصورة هي لجواز سفرك الفعلي',
          yourImageUploaded: '[صورة جواز السفر التي قمت بتحميلها في الخطوة السابقة]',
          showDetailsClearly: 'يجب أن تكون تفاصيل جواز السفر واضحة في الصورة، وإلا سيتم رفض اختبارك.',
          photoMeetsRequirements: 'إذا كنت تعتقد أن هذه الصورة تستوفي المتطلبات، يمكنك الاستمرار باستخدامها. ولكن ستحتاج إلى إدخال تفاصيل جواز سفرك يدويًا.',
          provideManually: 'التقديم يدويا',
          reuploadPassport: 'إعادة رفع الجواز',
          passportPhoneProcessing: 'يتم معالجة صورة الجواز',
          pleaseWait: 'يرجى الانتظار وعدم إغلاق الصفحة'
        },
        passportConfirmModal: {
          heading: 'تأكيد على معلومات جواز السفر',
          cnicHeading: 'تأكيد على معلومات الهوية الوطنية',
          isCorrectPassportInfo: 'هل معلومات جواز السفر الخاصة بك صحيحة؟',
          isCorrectInfo: 'هل معلوماتك صحيحة؟',
          infoBlockPassport: 'صورة جواز سفرك التي قمت بتحميلها في الخطوة السابقة',
          infoBlockNationalId: 'صورة الهوية الوطنية التي قمت بتحميلها في الخطوة السابقة:',
          verifyCheckboxPassport: 'يرجى التأكد من معلومات الجواز أعلاه بأنها صحيحة، لعدم إمكانية تعديل المعلومات بعد التسجيل.',
          verifyCheckbox: 'يرجى التأكد من المعلومات أعلاه بأنها صحيحة، لعدم إمكانية تعديل المعلومات بعد التسجيل.'
        },
        photoModal: {
          title: 'المتطلبات الأساسية للصورة الشخصية',
          pose: 'الوضعية',
          glasses: 'النظارات',
          headdress: 'أغطية الرأس',
          must: 'يجب أن يكون <span class="text-bold">كلّ من الوجه وأعلى</span> <span class="text-bold">الكتفان واضحان بدقة في الصورة</span>.',
          takeUp: 'يجب أن يشغل <span class="text-bold">الوجه </span>٧٠٪ - ٨٠٪ من مساحة الصورة.',
          size: 'يرجى ألا يتجاوز <span class="text-bold">حجم</span> الصورة <span class="text-bold">٢ ميغابايت.</span>',
          format: 'يرجى اختيار صور بصيغة <span class="text-bold">PNG, JPG</span> أو <span class="text-bold">JPEG</span>.',
          dimensions: 'يجب أن تكون الصورة حديثة وتم التقاطها يجب أن تكون الصورة بأبعاد <span class="text-bold">٤٠ (ارتفاع) ×</span> <span class="text-bold">٣٠ (عرض)</span> ودقة لا تقل عن <span class="text-bold">٧٢٠</span> بكسل.',
          less: ' في غضون الأشهر <span class="text-bold">الستة الماضية</span>.',
          colored: '<span class="text-bold">ملونة</span>',
          background: 'ملتقطة أمام <span class="text-bold">خلفية خالية وأن يكون لونها</span> <span class="text-bold">أبيض أو رمادي فاتح</span>.',
          clear: '<span class="text-bold">واضحة وذات دقة عالية</span> بدون تواجد أي تعارض بالغ أو ناقص للضوء.',
          colorBalance: 'ذات <span class="text-bold">ألوان متناسقة</span>، وتعكس ألوان البشرة بشكل طبيعي، ولا تظهر العيون باللون الأحمر.',
          contrast: 'واضحة مع <span class="text-bold">وجود تباين وفرق واضح بين</span> <span class="text-bold">الوجه وخلفية الصورة</span>.',
          shadows: 'مأخوذة بإضاءة موحدة، <span class="text-bold">وخالية من الظلال،</span> <span class="text-bold">والانعكاسات الضوئية، والتوهجات</span> في كلّ من الصورة وخلفيتها.',
          position: '<span class="text-bold">يجب أن يتوسط رأسك الصورة</span> <br/>كما ينبغي أن تنظر بشكل مستقيم ومباشر إلى كاميرا التصوير مع مراعاة أن يكون وجهك واضحاً بالكامل.',
          head: '<span class="text-bold">يجب أن يكون الرأس مستقيماً، فتجنّب إمالته أو تدويره بأي شكل من الأشكال.</span>',
          expression: '<span class="text-bold">يجب أن تكون تعابير الوجه واضحة بشكلها الطبيعي.</span><br>لذا تجنّب الابتسام والتحديق وغيرهما من التعابير الأخرى.',
          eye: '<span class="text-bold">يجب أن يكون الفم مغلقاً والعيون مفتوحة بوضوح</span>، ويجب ألا يغطيها شعر الرأس.',
          glass: 'لا <span class="text-bold">ترتدي النظارات الشمسية أو النظارات الملونة</span>',
          visible: 'إذا كنت عادة ما ترتدي النظارات الطبية، فيجب أن تبين الصورة العيون بوضوح وألا تغطي النظارات الطبية أي جزء منها.',
          notWearHeaddress: '<span class="text-bold">لا ترتدي أي نوع من أغطية</span> <span class="text-bold">الرأس</span> إلا إذا كان ذلك لأسباب دينية. لذلك، يرجى تجنب ارتداء القبعات وأي نوع من أغطية الرأس المخصصة للزينة.',
          clearlyVisible: 'إذا كنت ترتدي <span class="text-bold">غطاء الرأس</span> ل<span class="text-bold">أسباب دينية،</span> فتأكّد من أن تكون <span class="text-bold">كل ملامح الوجه</span> <span class="text-bold">واضحة بشكل جيد</span>.'
        },
        uploadPassport: {
          heading: 'تحميل جواز السفر',
          nationalIdHeading: 'تحميل الهوية الوطنية',
          reUploadPassport: 'إعادة تحميل جواز السفر',
          reUploadNationalId: 'إعادة تحميل الهوية الوطنية',
          passportInformative: 'يرجى تحميل أو التقاط صورة من جواز سفرك',
          nationalIdInformative: 'يرجى تحميل أو التقاط صورة لبطاقة الهوية الوطنية من الأمام والخلف',
          uploadFormat: 'يجب أن تكون الصورة بصيغة <span class="text-bold">PNG, JPG</span> أو <span class="text-bold">JPEG</span> وألا تتجاوز حجم 2 ميجابايت.',
          givenNames: 'الاسم الأول',
          enterName: 'أدخل اسمك الأول',
          surname: 'الاسم الأخير',
          enterSurname: 'أدخل اسمك الأخير',
          passportNumber: 'رقم الجواز',
          enterPassword: 'أدخل رقم جواز سفرك',
          country: 'الدولة',
          countryOfResidence: 'دولة الإقامة',
          chooseCountry: 'اختر الدولة',
          nationality: 'الجنسية',
          chooseNationality: 'اختر الجنسية',
          dateOfBirth: 'تاريخ الميلاد',
          datePlaceholder: '(ar) Date',
          yearPlaceholder: '(ar) Year',
          sex: 'الجنس',
          male: 'ذكر',
          female: 'أنثى',
          passportExpiry: 'تاريخ انتهاء صلاحية الجواز',
          nationalIdExpiry: 'تاريخ الانتهاء',
          selectDatePlaceholder: 'DD/MM/YYYY',
          passportTIP: 'يرجى التأكد من إدخال رقم الجواز وليس الرقم التسلسلي.',
          months: {
            jan: 'يناير',
            feb: 'فبراير',
            march: 'مارس',
            april: 'إبريل',
            may: 'مايو',
            jun: 'يونيو',
            jul: 'يوليو',
            aug: 'أغسطس',
            sep: 'سبتمبر',
            oct: 'أكتوبر',
            nov: 'نوفمبر',
            dec: 'ديسمبر'
          }
        },
        otherDetails: {
          uploadHeading: 'تحميل صورتك الشخصية',
          informative: 'يرجى تحميل أو التقاط صورة شخصية تظهر وجهك بالكامل.',
          uploadBtn: 'تحميل الصورة',
          reUploadBtn: 'إعادة تحميل الصورة',
          nationalId: 'الهوية الوطنية',
          nationalIdPlaceholder: 'أدخل هويتك الوطنية',
          nationalIdTip: 'أدخل رقم هويتك الوطنية كما تظهر في بطاقة الهوية.',
          educationLvl: 'مستوى التعليم',
          educationLvlPlaceholder: 'اختر التعليم',
          chooseExp: 'اختر الخبرة',
          education: {
            lvlOne: 'أمي /غير متعلم (غير قادر على القراءة أو الكتابة)',
            lvlTwo: 'متعلم (قادر على القراءة والكتابة)',
            lvlThree: 'الابتدائية (التأسيسية)',
            lvlFour: 'المتوسطة (الإعدادية)',
            lvlFive: 'الثانوية',
            lvlSix: 'دبلوم فأكثر'
          },
          knowledgeLvl: 'هل تتمتع بالمعرفة والمهارات اللازمة لمزاولة المهنة التي تنوي التقدم لها؟',
          knowledgeLvlPlaceholder: 'اختر مستوى المعرفة',
          knowledge: {
            lvlOne: 'نعم',
            lvlTwo: 'نعم، ولدي خبرة.',
            lvlThree: 'نعم، ولدي شهادات، دورات أو تدريب.'
          },
          experienceLvl: {
            label: 'مستوى الخبرة',
            lvlOne: 'أقل من 3 سنوات',
            lvlTwo: '3 – 5 سنوات',
            lvlThree: 'أكثر من 5 سنوات'
          },
          instituteLvl: {
            label: 'اختر الجهات التي حصلت منها على الشهادات، الدورات، أو التدريب',
            placeholder: 'اختر الجهة',
            lvlOne: 'أخرى',
            lvlTwo: 'وزارة العمل في مصر',
            lvlThree: 'National Skill Development Corporation (NSDC)',
            lvlFour: 'National Vocational & Technical Training Commission (NAVTTC)',
            lvlFive: 'Bureau of Manpower, Employment and Training (BMET)',
            lvlSix: 'Tertiary and Vocational Education Commission (TVEC)'
          },
          instituteName: 'اسم الجهة',
          institutePlaceholder: 'أدخل اسم الجهة',
          allDataIsValid: 'أقر بأن جميع البيانات المُدخلة صحيحة وتحت مسؤوليتي.'
        },
        contactInfo: {
          infoDescription: 'أدخل رقم جوالك أو بريدك الإلكتروني، واللذان سيتم استخدامهما لتسجيل الدخول إلى المنصة.',
          verifyMethod: 'الطريقة المفضلة للتحقق'
        },
        accountVerification: {
          emailTitle: 'التحقق من البريد الإلكتروني',
          phoneTitle: 'التحقق من رقم الجوال',
          phoneTitleOptional: 'التحقق من رقم الجوال (اختياري)',
          sendCode: 'تم إرسال رمز التحقق إلى',
          pleaseEnter: 'يُرجى إدخال الرمز أدناه أو',
          changePhone: 'تغيير رقم الجوال',
          changeEmail: 'تغيير البريد الإلكتروني',
          emailHasBeenVerified: 'تم التحقق من بريدك الإلكتروني بنجاح',
          phoneHasBeenVerified: 'لقد قمت بالتحقق من رقم هاتفك بنجاح',
          sendCodeToEmail: 'تم إرسال رمز التحقق على البريد البريد الإلكتروني المدخل'
        },
        leaveModal: {
          stopProcess: 'هل أنت متأكد من أنك تريد إيقاف عملية التسجيل؟',
          assessorRefLink: 'تم بدء التسجيل عبر رابط دعوة. إذا غادرت هذه الصفحة، لن يكتمل تسجيلك.',
          userSure: 'نعم، أنا متأكد',
          userNo: 'لا'
        }
      },
      changePassword: {
        done: 'تم',
        changePassword: 'تغيير كلمة المرور',
        currentPassword: 'كلمة المرور الحالية',
        newPassword: 'كلمة المرور الجديدة',
        confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        successUpdated: 'تم تحديث كلمة المرور بنجاح!'
      },
      signUp: {
        signUp: 'التسجيل',
        registerIntoSVP: 'التسجيل في برنامج الاعتماد المهني',
        fullName: 'الاسم الكامل',
        firstName: 'الاسم الاول',
        lastName: 'الاسم الاخير',
        newPassword: 'كلمة المرور الجديدة',
        confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        nationalId: 'الهوية الوطنية',
        cnic: 'رقم الهوية',
        enterNationalId: 'أدخل رقم هويتك',
        enterNationalIdHint: 'يرجى إدخال رقم الهوية الحكومية (مثل: الهوية الوطنية، الرقم القومي، CNIC، SSN ...إلخ)',
        passportNumber: 'رقم جواز السفر',
        phoneNumber: 'رقم الجوال',
        countryOfResidence: 'دولة الإقامة',
        nationality: 'الجنسية',
        enterYourName: 'أدخل أسمك',
        enterYourFirstName: 'ادخل اسمك الاول',
        enterYourLastName: 'ادخل اسمك الاخير',
        enterYourNationalId: 'أدخل الهوية الوطنية الخاصة بك',
        enterYourPassportNumber: 'أدخل رقم جواز سفرك',
        chooseCountry: 'اختر الدولة',
        chooseNationality: 'اختر الجنسية',
        enterYourEmail: 'أدخل بريدك الإلكتروني',
        enterNewPassword: 'أدخل كلمة المرور',
        email: 'البريد الإلكتروني',
        dateOfBirth: 'تاريخ الميلاد',
        dateOfBirthPlaceholder: 'أدخل تاريخ ميلادك',
        passportExpiration: 'تاريخ انتهاء صلاحية جواز السفر',
        passportExpirationPlaceholder: 'ادخل تاريخ انتهاء الصلاحية',
        checkboxText: 'أقر بأن جميع البيانات المدخلة صحيحة وتحت مسؤوليتي',
        dontHaveEmail: 'ليس لدي بريد الكتروني',
        description: '<b>لا يمكن تعديل البيانات بعد إنشاء الحساب،</b> الرجاء التأكد من إدخال معلومات',
        phoneNumberError: 'رقم الهاتف هذا مستخدم بالفعل مع حساب آخر',
        backToRegistration: 'العودة الى صفحة التسجيل',
        phoneNumberErrorLocal: 'رقم الهاتف المدخل غير مكتمل',
        tooltip: {
          firstName: 'أدخل اسمك الأول كما هو موضح في جواز السفر',
          lastName: 'أدخل اسمك الأخير كما هو موضح في جواز السفر',
          nationalId: 'أدخل رقم الهوية الوطنية الخاصة بك كما هو موضح في بطاقة الهوية',
          passportNumber: 'أدخل رقم جواز السفر الصحيح الخاص بك',
          countryOfResidence: 'الرجاء اختيار بلد الاقامة الحالي',
          nationality: 'اختر جنسيتك كما هو موضح في جواز السفر',
          phoneNumber: 'الرجاء إدخال رقم هاتفك الذي سيتم استخدامه في التحقق من OTP',
          email: 'الرجاء إدخال بريد إلكتروني صالح ليتم استخدامه في تسجيل دخولك إلى البوابة',
          newPassword: 'كلمة المرور الجديدة',
          confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
          passportNumberHint: 'الرجاء التأكد من إدخال رقم الجواز وليس الرقم التسلسلي'
        },
        welcomeIntoSVP: 'مرحبًا بكم في برنامج الفحص المهني الدولي!',
        fillInCode: 'ادخل الرمز المرسل لرقم الجوال المسجل',
        enterCode: 'ادخل الرمز',
        fillOutAllFields: 'الرجاء تعبئة جميع الحقول الإلزامية',
        mustExist: 'حقل الزامي',
        cantBeBlank: 'حقل الزامي',
        fieldNames: {
          country: 'الدولة',
          national_id: 'رقم الهوية الوطنية',
          nationality_id: 'الجنسية',
          passport_number: 'رقم جواز السفر',
          full_name: 'الاسم بالكامل',
          email: 'البريد الإلكتروني',
          password: 'كلمة المرور',
          password_confirmation: 'تآكيد كلمة المرور',
          date_of_birth: 'تاريخ الميلاد',
          passport_expiration_date: 'تاريخ انتهاء صلاحية جواز السفر'
        }
      },
      forgotPassword: {
        heading: 'إعادة تعيين كلمة المرور',
        email: 'رجاءا أدخل بريدك الإلكتروني',
        email_error: 'خطأ في البريد الإلكتروني.',
        send_btn: 'إرسال',
        continue_btn: 'استمرار',
        sign_in_text: 'لتسجيل الدخول',
        sign_in_link: 'تسجيل الدخول',
        submit_form_text: 'سوف تصلك رسالة بها رابط لاستعادة كلمة المرور على عنوان بريدك الإلكتروني في غضون بضع دقائق.',
        emailBtnText: 'البريد الإلكتروني',
        phoneNumberBtnText: 'رقم الجوال',
        passwordResetInstructions: 'لقد أرسلنا تعليمات إعادة تعيين كلمة المرور إلى:',
        checkSpam: 'إذا لم تستلمها قريبًا ، فتحقق من مجلد البريد العشوائي أو',
        sendEmailAgain: 'أرسل البريد الإلكتروني مرة أخرى',
        backToSignIn: 'العودة إلى تسجيل الدخول',
        backTo: 'العودة إلى',
        inputPhoneNumber: 'إدخال رقم الهاتف المسجل في المنصة',
        smsWasSend: 'لقد أرسلنا رسالة نصية قصيرة تحتوي على رمز التأكيد إلى:',
        phoneNumberErrorLocal: 'رقم الهاتف غير صحيح',
        checkYourEmail: 'تحقق من بريدك الإلكتروني'
      },
      registrationAssessorForm: {
        fullName: 'الاسم بالكامل',
        fullNamePlaceholder: 'الاسم الكامل ، لا يوجد اختصار',
        dateOfBirth: 'تاريخ الميلاد',
        dateOfBirthPlaceholder: 'أدخل تاريخ ميلادك',
        gender: 'الجنس',
        genderPlaceholder: 'أدخل الجنس',
        nationality: 'الجنسية',
        nationalityPlaceholder: 'أدخل الجنسية',
        nationalId: 'رقم الهوية الوطنية',
        nationalIdPlaceholder: 'أدخل رقم الهوية الوطنية الخاص بك',
        passportNumber: 'رقم جواز السفر',
        passportNumberPlaceholder: 'أدخل رقم جواز السفر الخاص بك',
        phoneNumber: 'رقم الجوال',
        email: 'البريد الالكتروني',
        emailPlaceholder: 'أدخل بريدك الإلكتروني',
        passportUpload: 'تحميل جواز السفر',
        passportUploadTitle: 'إرفاق نسخة أو صورة من جواز السفر',
        instituteName: 'اسم المعهد'
      },
      scopeOfWork: {
        country: 'الدولة',
        chooseCountry: 'اختر الدولة',
        city: 'المدينة',
        chooseCity: 'اختر المدينة',
        cityHint: 'ستكون المدن المختارة من ضمن نطاق عملك'
      },
      detailsForm: {
        category: 'الفئة',
        chooseCategories: 'اختر الفئات',
        yes: 'نعم',
        no: 'لا',
        none: 'لايوجد',
        educationalQualifification: 'ما هو مؤهلك التعليمي؟',
        moreTen: 'أكثر من 10 سنوات',
        five: '5 سنوات',
        three: '3 سنوات',
        lessThree: 'أقل من 3 سنوات',
        moreThree: 'أكثر من 3 سنوات',
        more_than_3: 'أكثر من 3 سنوات',
        less_than_3: 'أقل من 3 سنوات',
        no_international_experience: 'لايوجد',
        sixteenMore: '16 سنة فما فوق',
        fourteen: '14 سنة',
        dae: 'دبلوم',
        uploadQualifications: 'ارفاق المؤهل التعليمي',
        attachLicensesCertifications: 'إرفاق التراخيص والشهادات الخاصة بك',
        whatIsYourExperience: 'ما هي خبرتك؟',
        uploadExperienceCertificate: 'أرفق شهادات الخبرة',
        certifiedOther: 'هل أنت مقيّم معتمد من أي جهة أخرى؟',
        whichLanguage: 'ما هي اللغات التي تعرفها؟',
        chooseLanguages: 'اختر اللغات',
        englishMandatory: 'اللغة الإنجليزية إلزاميه',
        itSkills: 'مهارات تقنية المعلومات الأساسية',
        diplomaInIt: 'دبلوم فأكثر في تكنولوجيا المعلومات.',
        certificateInIt: 'شهادة في تكنولوجيا المعلومات',
        msOffice: 'مايكروسوفت أوفيس أو انترنت',
        onlineTools: 'هل لديك المعرفة والمهارة للتعامل مع الأدوات عبر الإنترنت مثل زوم وما إلى ذلك؟',
        profQualification: 'أي مؤهل مهني؟',
        internalQualification: 'أي مؤهل دولي؟',
        degree: 'الدرجة العلمية',
        diploma: 'شهادة دبلوم',
        training: 'دورة تدريب',
        internalExperience: 'هل سبق لك العمل خارج دولتك؟',
        submitRequest: 'تقديم الطلب',
        termsAndConditions: 'الشروط والأحكام',
        agree: 'أوافق على',
        success: 'تم ارسال الطلب بنجاح!',
        successText: 'تم استلام طلب التسجيل الخاص بك وهو حاليًا قيد المراجعة من قبل المشرع. سنبلغك بالنتيجة عبر البريد الإلكتروني والرسائل النصية القصيرة.',
        experienceCertificateHintText: 'شهادات الخبرة، توصيات، أو إخلاء طرف',
        instituteLvlLabel: 'اختر الجهات',
        instituteLvlPlaceholder: 'اختر الجهة',
        lessThanThreeYears: '2-3 أو أقل من 3 سنوات.',
        moreThanThreeYears: 'أكثر من 3 سنوات.',
        skillsOfOnlineTools: 'هل لديك المعرفة والمهارة للتعامل مع الأدوات عبر الإنترنت مثل زوم وما إلى ذلك؟'
      },
      assessorRegistration: {
        partnership: 'شراكة',
        registerAsAssessor: 'التسجيل كمقيّم',
        registerIntoSVP: 'قم بالتسجيل في منصة الاعتماد المهني',
        subtitle: 'املأ معلوماتك أدناه إذا كنت ترغب في الانضمام إلى برنامج الاعتماد المهني بصفتك مقيمًا',
        terms: 'تطبق الشروط والأحكام',
        infoDescription: 'املأ معلوماتك أدناه إذا كنت ترغب في الانضمام إلى منصة الفحص المهني الخارجي بصفتك <b>مقيّمًا<b/>',
        documentTypeSwitchTitle: 'اختر نوع المستند الذي ترغب بالتسجيل فيه',
        backToBasicInformation: 'العودة للمعلومات الأساسية',
        backToScopeOfWork: 'العودة الى نطاق العمل',
        stepper: {
          one: 'المعلومات الأساسية',
          two: 'نطاق العمل',
          three: 'معلومات <br/> التواصل',
          four: 'التحقق من الحساب',
          five: 'تفاصيل المؤهل',
          six: 'الخبرات والمهارات'
        },
        passport: 'جواز السفر',
        nationalId: 'الهوية الوطنية'
      }
    }
  }
};
