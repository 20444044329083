import LaborsApi from '@/api/labors.api';
import { PER_PAGE } from '@/config';

const module = {
  state: {
    summaryParams: {
      sessionData: null,
      occupation: null,
      language: null
    }
  },
  getters: {
    getSummary: state => state.summaryParams
  },
  mutations: {
    setSummaryParams (state, params) {
      state.summaryParams = params;
    }
  },
  actions: {
    /** getLabors
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    getLabors: async ({ commit, rootState }, params) => {
      return await LaborsApi.getLabors(rootState.user.userSpace, {
        ...params,
        per_page: PER_PAGE
      });
    },
    /** getLaborsList
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    getLaborsList: async ({ commit, rootState }, params) => {
      return await LaborsApi.getLaborsList(rootState.user.userSpace, {
        ...params,
        per_page: PER_PAGE
      });
    },
    /** getRegisteredLaborsList
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    getRegisteredLaborsList: async ({ commit, rootState }, params) => {
      return await LaborsApi.getRegisteredLaborsList(rootState.user.userSpace, { ...params, per_page: PER_PAGE });
    },
    /**
     * uploadFile
     * @param rootState
     * @param formDta
     * @returns {Promise<unknown>}
     */
    uploadFile: async ({ rootState }, formData) => {
      return await LaborsApi.uploadFile(rootState.user.userSpace, formData);
    },
    /** createLabor
     * @param commit
     * @param rootState
     * @param data
     * @returns {Promise<unknown>}
     */
    createLabor: async ({ commit, rootState }, data) => {
      return await LaborsApi.createLabor(rootState.user.userSpace, data);
    },
    updateLabor: async ({ commit, rootState }, { email, id }) => {
      const payload = {
        labor: {
          email
        }
      };
      return await LaborsApi.updateLabor(rootState.user.userSpace, payload, id);
    },
    /** getLaborsByWithdrawalId
     * @param commit
     * @param rootState
     * @param params
     * @returns {Promise<unknown>}
     */
    getLaborsByWithdrawalId: async ({ commit, rootState }, params) => {
      return await LaborsApi.getLaborsByWithdrawalId(rootState.user.userSpace, {
        ...params,
        per_page: PER_PAGE
      });
    },
    /** sendLaborsCertificates
     * @param commit
     * @param rootState
     * @param params
     * @returns {Promise<unknown>}
     */
    sendLaborsCertificates: async ({ commit, rootState }, params) => {
      return await LaborsApi.sendLaborsCertificates(rootState.user.userSpace, params);
    },
    /** downloadLaborsCsvFile
     * @param {Number} id withdrawal ID
     * @returns {Promise<unknown>}
     */
    downloadLaborsCsvFile: async ({ commit }, params) => {
      return await LaborsApi.downloadLaborsCsvFile(params);
    },
    /** downloadRegisteredLaborsCsvFile
     * @param {Number} id withdrawal ID
     * @returns {Promise<unknown>}
     */
    downloadRegisteredLaborsCsvFile: async ({ commit }, params) => {
      return await LaborsApi.downloadRegisteredLaborsCsvFile(params);
    }
  }
};

export default module;
