<template>
  <div class="user-info-old">
    <b-dropdown class="dropdown-right">
      <div
        slot="trigger"
        class="user-info-old__opener"
      >
        <div class="user-info-old__avatar mr-md-2">
          <img
            :src="imgSrc"
            alt="avatar"
          >
        </div>
        <div class="user-info-old__desc">
          <div
            class="user-info-old__name"
            v-text="name"
          />
          <div
            class="user-info-old__role"
            v-text="role"
          />
        </div>
        <i class="user-info-old__icon icon-options"></i>
      </div>
      <b-dropdown-item has-link>
        <router-link
          v-if="showNewChangePass"
          :to="{ name: 'changePass' }"
          v-text="$t('userInfo.changePassword')"
        />
        <router-link
          v-else
          :to="{ name: 'changePassOld' }"
          v-text="$t('userInfo.changePassword')"
        />
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isLegislator"
        has-link
      >
        <router-link
          :to="{name: 'LegislatorAccountInformation'}"
          v-text="$t('userInfo.accountInformation')"
        />
      </b-dropdown-item>
      <b-dropdown-item has-link><a
        href="logout"
        v-text="$t('userInfo.logout')"
        @click.prevent="logout"
      />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'user-info',
  props: {
    name: {
      type: String
    },
    role: {
      type: String
    },
    logoSrc: {
      type: String
    },
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isLegislator: 'getIsLegislator',
      isLabor: 'getIsLabor'
    }),
    imgSrc () {
      return this.showLogo ? this.logoSrc : require('@/assets/img/user.svg');
    },
    showNewChangePass () {
      return (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_CHANGE_PASSWORD));
    }
  },
  methods: {
    ...mapActions(['setTouch', 'clearPermissions']),
    async logout () {
      await this.setTouch(false); // Hide Confirmation popup before leave page
      await this.$auth.logout();
      await this.clearPermissions();
    }
  }
};
</script>
