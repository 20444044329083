import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { langs } from '@/config';

import messages from './messages';

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      day: 'numeric', month: 'short'
    },
    month: {
      month: 'short'
    }
  },
  ar: {
    short: {
      day: 'numeric', month: 'short'
    },
    month: {
      month: 'short'
    }
  }
};

const i18n = new VueI18n({
  locale: langs[0],
  fallbackLocale: langs[0],
  silentTranslationWarn: true,
  dateTimeFormats,
  messages
});

Vue.i18n = i18n;

export default i18n;
