import _omit from 'lodash/omit';

const state = {
  /**
    * Type definition for a filter entry.
    * - key (String): A filter key, ussed as main entry point
    * - `labelKey` (String): A key for localization or UI labels.
    * - `value` (Array|String|Number): An array of filter value.
    *
    * Example:
    * {
    *   sessionStatus: {
    *     labelKey: 'label.key',
    *     value: [1, 2, 3]
    *   }
    * }
  */
  filterInfo: {}
};

const getters = {
  filterInfo: (state) => {
    return state.filterInfo;
  }
};

const mutations = {
  'SET_FILTER_INFO' (state, newValues) {
    state.filterInfo = { ...newValues };
  }
};
const actions = {
  setFilterInfo: ({ commit }, value) => {
    commit('SET_FILTER_INFO', { ...value });
  },
  removeFilterKey: ({ commit, state }, key) => {
    commit('SET_FILTER_INFO', _omit(state.filterInfo, key));
  },
  setFilterKeyWithInfo: ({ commit, state }, { key, filterData }) => {
    commit('SET_FILTER_INFO', { ...state.filterInfo, [key]: filterData });
  },
  clearFiltersInfo: ({ commit }) => {
    commit('SET_FILTER_INFO', {});
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
