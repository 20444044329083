import { get, isEmpty } from 'lodash';

import { legislatorStatuses, permissionsNames, testCenterStatuses, userSpaces } from '@/config';
import { getUserRole } from '@/services/auth';
import store from '@/store';

/**
 * async Fn
 * @param {Array} rolesName
 */
export const setUserPermissions = async (rolesName = [], user) => {
  let viewOnly = false;

  if (get(user, 'legislator.status', '') === legislatorStatuses.suspended) {
    viewOnly = true;
  }

  store.dispatch('setUserRoles', rolesName);

  if (!rolesName.length) return; // Return if we have no 'rolesName'

  if (getUserRole(rolesName) === permissionsNames.center_owner) {
    const statuses = await getTestCentersStatuses({ owner_full_name: user?.full_name });

    // Set ViewOnly if Test center has status 'inactive'
    if (statuses?.every(status => status === testCenterStatuses.inactive)) {
      viewOnly = true;
    }
  }

  store.dispatch('setPermissionViewOnly', viewOnly);

  // Get permissions object from API
  await store.dispatch('getPermissions');
};

/**
 * @param {Array} rolesName
 */
export const setUserSpace = (rolesName) => {
  // Set user space based on user role
  const role = getUserRole(rolesName);
  if (role) store.dispatch('setUserSpace', userSpaces[role]);
};

/**
 * async Fn
 * @param {Object} params
 * ex: { owner_full_name: 'Name' }
 * @returns {Array of statuses}
 */
export const getTestCentersStatuses = async (params) => {
  try {
    const { data } = await store.dispatch('getTestCenters', params);
    return data?.test_centers?.map(item => item.status);
  } catch (err) {
    console.error(err);
    return [];
  }
};

/**
 * @param {Object} permissions Object of permissions. EX: { labors, test_centers, payment }
 * @param {String} curentPath EX: /payment
 * @returns {String} /upload
 */
export const getPermittedPath = (permissions, curentPath = '') => {
  if (isEmpty(permissions)) return '/upload';

  const { labors, test_centers, payment } = permissions;
  const arr = [];
  let permittedPath = '';

  if (labors.view_uploaded_files) arr.push('/upload');
  if (payment.make_payment) arr.push('/payment');
  if (test_centers.view) arr.push('/centers');
  if (payment.view_transaction_history) arr.push('/history');

  permittedPath = arr.find((item) => new RegExp(item || '').test(curentPath)) ? curentPath : arr[0];

  return permittedPath;
};

export const isUserSuspended = (userObj) => userObj.legislator?.status === legislatorStatuses.suspended;
