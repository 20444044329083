import AuthLayout from '@/app/auth/layout/AuthLayout.vue';
import { validateToken } from '@/app/auth/routes/utils';
import { lazyLoading } from '@/app/shared/router/utils';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import store from '@/store';
import { isFeatureAvailable } from '@/utils/featureFlags';
// Two identical paths + different layouts were added during the redesign to save the state of the application.
// Feature flags for the same paths are also connected.
// During the redesign process, you need to transfer pages from the AuthLayoutOld to the AuthLayoutNew.
export const authRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    meta: { auth: false },
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'login',
        props: true,
        component: lazyLoading('auth', 'Login'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_LOGIN)) next();
          else next({ name: 'loginOld' });
        }
      },
      {
        path: 'register',
        name: 'register',
        component: lazyLoading('auth', 'Register'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_REGISTER)) next();
          else next({ name: 'registerOld' });
        }
      },
      {
        path: 'activate',
        name: 'activateAccount',
        component: lazyLoading('auth', 'ActivateAccount'),
        async beforeEnter (to, from, next) {
          await validateToken(to, from, next);
        }
      },
      {
        path: 'forgot',
        name: 'forgotPass',
        component: lazyLoading('auth', 'ResetPassword'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          if (!isFeatureAvailable(FeatureFlagDictionary.USE_NEW_FORGOT_PASSWORD)) next({ name: 'forgotPassOld' });
          else next();
        }
      },
      {
        path: 'set-pass',
        name: 'setPass',
        component: lazyLoading('auth', 'SetPassword'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          const password_token = to.query?.password_token;
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_SET_PASSWORD)) {
            await validateToken(to, from, next);
          } else {
            next({ name: 'setPassOld', query: { password_token } });
          }
        }
      },
      {
        path: 'change-pass',
        name: 'changePass',
        meta: { auth: true },
        component: lazyLoading('auth', 'ChangePassword'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          // regarding ticket PVPE-4974 (new change pass for labors)
          if (!isFeatureAvailable(FeatureFlagDictionary.USE_NEW_CHANGE_PASSWORD) && !store?.state?.user?.isLabor) next({ name: 'ChangePasswordOld' });
          else next();
        }
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { auth: false },
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'loginOld',
        props: true,
        component: lazyLoading('auth', 'LoginOld'),
        beforeEnter (to, from, next) {
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_LOGIN)) next({ name: 'login' });
          else next();
        }
      },
      {
        path: '2fa',
        name: 'login2fa',
        component: lazyLoading('auth', 'Login2fa'),
        props: true,
        beforeEnter (to, from, next) {
          if (store.state.authentication.twoFA && !isFeatureAvailable(FeatureFlagDictionary.USE_NEW_LOGIN)) return next();
          return next({ name: 'login' });
        }
      },
      {
        path: 'forgot-pass-old',
        name: 'forgotPassOld',
        component: lazyLoading('auth', 'ForgotPassOld'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_FORGOT_PASSWORD)) next({ name: 'forgotPass' });
          else next();
        }
      },
      {
        path: 'set-pass-old',
        name: 'setPassOld',
        component: lazyLoading('auth', 'SetPasswordOld'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          const password_token = to.query?.password_token;
          if (!isFeatureAvailable(FeatureFlagDictionary.USE_NEW_SET_PASSWORD)) {
            await validateToken(to, from, next);
          } else {
            next({ name: 'setPass', query: { password_token } });
          }
        }
      },
      {
        path: 'change-pass',
        name: 'changePassOld',
        meta: { auth: true },
        component: lazyLoading('auth', 'ChangePasswordOld'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          // regarding ticket PVPE-4974 (new change pass for labors)
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_CHANGE_PASSWORD) && store?.state?.user?.isLabor) next({ name: 'ChangePassword' });
          else next();
        }
      },
      {
        path: 'register',
        name: 'registerOld',
        props: true,
        component: lazyLoading('auth', 'RegisterOld'),
        async beforeEnter (to, from, next) {
          await store.dispatch('getFeatureFlagsConfiguration');
          if (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_REGISTER)) next({ name: 'register' });
          else next();
        }
      },
      {
        path: 'register-assessor',
        name: 'registerAssessor',
        props: true,
        component: lazyLoading('auth', 'Register'),
        meta: { isAssessor: true }
      },
      {
        path: 'tc-register-assessor',
        name: 'tcRegisterAssessor',
        props: true,
        meta: { auth: true, isAssessor: true },
        component: lazyLoading('auth', 'Register')
      },
      {
        path: '*',
        redirect: { name: 'login' }
      }
    ]
  }
];
