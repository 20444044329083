<template>
  <svp-box
    :use-shadow="true"
    :class="wrapperClass"
    :heading="headLabel"
    class="q-box-form modal-form__box"
  >
    <div>
      <div
        v-if="icon"
        class="text-center mb-4"
      >
        <img
          v-if="iconType === 'error'"
          src="../../../../assets/img/icon-error2.svg"
          width="56px"
        >
        <img
          v-if="iconType === 'error-triangle'"
          src="../../../../assets/img/icon-error3.svg"
          width="80px"
        >
        <img
          v-if="iconType === 'success'"
          src="../../../../assets/img/success-icon.svg"
          width="56px"
        >
        <img
          v-if="iconType === 'alert'"
          src="../../../../assets/img/alert-icon.svg"
          width="56px"
        >
      </div>
      <p
        v-if="headText"
        class="modal-form__heading"
        v-html="headText"
      />
      <p
        v-if="headSubText"
        class="modal-form__sub-heading"
        v-html="headSubText"
      />
      <p
        v-if="modalText"
        class="modal-form__text"
        v-html="modalText"
      />
      <div
        v-if="image"
        class="modal-form-image-wrapper"
      >
        <img
          :src="imgPath"
          alt=""
        />
      </div>
      <div class="modal-form__btns-wrap d-md-flex justify-content-md-center">
        <button
          slot="submitButton"
          :class="{'reverse-button': reverseButtonsOrder}"
          class="btn btn-lg btn--primary text-capitalize"
          type="button"
          v-text="$t(confirmBtnText)"
          @click.prevent="confirm"
        />
        <button
          v-if="showCancelBtn"
          class="btn btn-lg btn--border-primary text-capitalize"
          type="button"
          v-text="$t(cancelBtnText)"
          @click.prevent="cancel"
        />
      </div>
    </div>
  </svp-box>
</template>

<script>

import SvpBox from '@/app/shared/components/appShell/box/SvpBox.vue';
export default {
  name: 'ConfirmModal',
  components: {
    SvpBox
  },
  props: {
    headLabel: {
      type: String
    },
    headText: {
      type: String
    },
    headSubText: {
      type: String
    },
    modalText: {
      type: String,
      required: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'error'
    },
    image: {
      type: Boolean,
      default: false
    },
    imageSrc: {
      type: String,
      default: ''
    },
    confirmBtnText: {
      type: String,
      default: 'confirm'
    },
    cancelBtnText: {
      type: String,
      default: 'cancel'
    },
    wrapperClass: {
      type: String
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    reverseButtonsOrder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imgPath () {
      return require(`@/assets/img/${this.imageSrc}`);
    }
  },
  methods: {
    confirm () {
      this.$parent.$emit('confirm');
      this.$emit('close');
    },
    cancel () {
      this.$parent.$emit('cancel');
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss">
.modal-form__box {
  color: $color-black;
}
</style>
