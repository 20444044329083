<template>
  <header class="svp-header">
    <div class="content">
      <slot>
        <div class="ui-controls">
          <div
            v-if="useAside"
            :class="{'is-open': sidebarIsOpen}"
            class="burger"
            @click="updateSidebar"
          >
            <div
              v-for="index in 3"
              :key="index"
              class="line"
            />
          </div>
          <LanguageSwitcher class="lang-switcher"/>
        </div>

        <div class="logo">
          <router-link :to="{ name: 'Home' }">
            <div
              v-if="!isPhone"
              class="logo-mobile"
            >
              <img
                :src="require(`@/assets/img/svp-logo-mobile.svg`)"
                alt="mobile-logo"
                width="40px"
              >
            </div>
            <div
              v-else
              class="logo-desktop"
            >
              <img
                :src="require(`@/assets/img/svp-logo.png`)"
                alt="desktop-logo"
                width="200px"
              >
            </div>
          </router-link>
        </div>

        <div class="user-controls">
          <LanguageSwitcher class="lang-switcher"/>
          <NotificationsInfo v-if="useNotification"/>
          <UserInfoOld
            :logoSrc="logoSrc"
            :name="user.full_name"
            :role="role"
            :showLogo="showLogo"
          />
        </div>
      </slot>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import LanguageSwitcher from '@/app/shared/components/ui/LanguageSwitcher.vue';
import NotificationsInfo from '@/components/NotificationsInfo.vue';
import UserInfoOld from '@/components/UserInfoOld.vue';
import adaptiveMixin from '@/mixins/adaptiveMixin.vue';

export default {
  name: 'SvpHeaderOld',
  components: { UserInfoOld, NotificationsInfo, LanguageSwitcher },
  mixins: [adaptiveMixin],
  props: {
    sidebarIsOpen: {
      type: Boolean,
      default: false
    },
    useNotification: {
      type: Boolean,
      default: true
    },
    useAside: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getIsLegislator', 'getIsAssessor', 'accountInfo']),
    role () {
      if (this.getIsLegislator) return this.$t('userInfo.legislator');
      if (this.getIsAssessor) return this.$t('userInfo.assessor');
      return '';
    },
    user () {
      return this.$auth.user() || {};
    },
    userLegislator () {
      return this.user.legislator || {};
    },
    logoSrc () {
      return this.accountInfo ? this.accountInfo.logo_url : this.userLegislator.logo_url;
    },
    showLogo () {
      return (this.accountInfo && this.getIsLegislator) ? this.accountInfo.show_logo : false;
    }
  },
  methods: {
    updateSidebar () {
      this.$emit('update:sidebarIsOpen', !this.sidebarIsOpen);
    }
  }
};
</script>

<style lang="scss" scoped>
.svp-header {
  height: 70px;
  position: fixed;
  z-index: 998;
  left: 0;
  right: 0;
  box-shadow: $header-shadow;
  background-color: $color-white;
  padding: 5px 10px;
  @include media('>phone') {
    padding: 5px 20px;
  }

  .content {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .ui-controls {
      display: flex;
      align-items: center;
      gap: 10px;
      @include media('>phone') {
        gap: 20px;
      }

      .burger {
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
        z-index: 40;

        &.is-open {
          .line {
            &:nth-child(1) {
              transform: translateY(7px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translateY(-7px) rotate(-45deg);
            }
          }
        }

        .line {
          width: 25px;
          height: 2px;
          background-color: $color-primary;
          transition: transform 0.3s ease, opacity 0.3s ease;
        }

        @include media('>tablet') {
          display: none;
        }
      }

      .lang-switcher {
        @include media('>tablet') {
          display: none;
        }
      }
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include media('>tablet') {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &-mobile {
        @include media('>tablet') {
          display: none;
        }
      }

      &-desktop {
        display: none;
        @include media('>tablet') {
          display: block;
        }
      }
    }

    .user-controls {
      display: flex;
      align-items: center;
      gap: 10px;

      .user-info__desc,
      .user-info-old__desc {
        display: none;
        @include media('>tablet') {
          display: block;
        }
      }

      @include media('>phone') {
        gap: 20px;
      }

      .lang-switcher {
        display: none;
        @include media('>tablet') {
          display: block;
        }
      }
    }
  }
}
</style>
