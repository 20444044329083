import Gateway from '@/api/index';

class CountriesApi {
  getCountryList = (url, params) => {
    return Gateway.get(`${url}/countries`, { params });
  }

  getCountryById = (url, id) => {
    return Gateway.get(`${url}/countries/${id}`);
  }

  getNationalities = (url, params) => {
    return Gateway.get(`${url}/nationalities`, { params });
  }

  getCitiesByCountry = (country) => {
    return Gateway.get(`https://countriesnow.space/api/v0.1/countries/cities/q?country=${country}`);
  }

  getCitiesWithTCes = (params) => {
    return Gateway.get('/visitor_space/cities', { params });
  }

  getActiveCountryCodes = () => {
    return Gateway.get('/visitor_space/test_centers/active_country_codes');
  }
}

export default new CountriesApi();
