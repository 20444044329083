import ActionCable from 'actioncable';

import { ACTION_CABLE_URL } from '@/config';
import LocalStorageService from '@/services/localStorageService';

let cable = null;
const channels = {};

function createCable () {
  const token = LocalStorageService.getAccessToken();
  if (cable || !token || token === 'null') return;

  cable = ActionCable.createConsumer(`${ACTION_CABLE_URL}?token=${token}`);
}

function createChannel (channelName, callbacks) {
  createCable();

  const channel = cable.subscriptions.create(channelName, callbacks);
  channels[channelName] = channel;
  return channel;
}

function unsubscribeChannel (channelName) {
  if (!channels[channelName]) return;
  channels[channelName].unsubscribe();
  delete channels[channelName];
}

function sendMessage (channelName, message) {
  if (!channels[channelName]) return;

  channels[channelName].send(message);
}

export default { createChannel, unsubscribeChannel, sendMessage };
