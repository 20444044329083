export const laborTranslations = {
  en: {
    labor: {
      nav: {
        accountDashboard: 'Account Dashboard',
        booking: 'Booking',
        transactionHistory: 'Transactions History',
        bookAnAppointment: 'Book an appointment',
        rescheduleAppointment: 'Reschedule appointment'
      },
      booking: {
        getCertified: 'Get Certified',
        bookTest: 'Book for the professional examination test',
        bookAnAppointment: 'Book an appointment',
        bookedAppointments: 'Booked appointments',
        emptyAppointments: 'You don’t have any appointments yet',
        category: 'Category',
        dateOfBooking: 'Date Of Booking',
        status: 'Status',
        manageBooking: 'Manage Booking',
        occupation: 'Occupation',
        bookingNo: 'Booking No.',
        viewAppointmentDetails: 'View appointment details',
        downloadTicket: 'Download ticket',
        downloadCertificate: 'Download certificate',
        bookingStatus: 'Booking Status',
        testDate: 'Test Date'
      },
      bookingSteps: {
        bookAnAppointment: 'Book an appointment',
        chooseCategoryAndCity: 'Choose Category and City',
        chooseDateAndSession: 'Choose Date and Session',
        payment: 'Payment',
        paymentInfoHint: 'Please enter your card details to complete the booking',
        chooseCategory: 'Choose Category',
        chooseCity: 'Choose City',
        category: 'Category',
        city: 'City',
        chooseDate: 'Choose Date',
        chooseSession: 'Choose Session',
        chooseTimeSlot: 'Choose Time Slot',
        timeSlotMsg: 'Time is indicated according to the local time zone of the selected city',
        alertMessage: 'Time is indicated according to the local time zone of the selected city',
        categoryDescription: 'Based on your <b>selected occupation</b> your exam category will be: <h3>{name}</h3> Please note that this <b>category and occupation key will be checked by the embassy</b> so <b>make sure you’ve selected the correct occupation</b> as shown in your visa application',
        categoryDescriptionNew: 'Based on your <b>selected occupation</b> your exam category will be: <br><h2>{name}</h2>',
        noExams: 'We\'re sorry, but there are currently <b>no exams planned for the category</b> based on the occupation you have selected. Please check back later.',
        back: 'Back',
        language: 'Language',
        chooseLanguage: 'Choose Language',
        selectLanguage: 'Select language',
        chooseLanguageText: 'Choose the language in which you want <b>to take the test</b>',
        occupationCode: 'Occupation Code',
        categoryAndOccupationWillBe: 'Please note that this category and occupation key will be checked by the embassy so make sure you’ve selected the correct occupation as shown in your visa application',
        important: 'Important',
        chooseOccupationAndCity: 'Choose Occupation and City',
        occupationAndCity: 'Occupation and City',
        chooseOccupation: 'Choose Occupation',
        noSessionsAvailable: 'Sorry, there are currently no test sessions available in the selected category based on your chosen occupation. Please check again later.',
        noSeatsAvailable: 'There are no seats available. Please try booking another test session.',
        disclaimerDescription: 'You can change the test date, but only up to 72 hours before the test.<br> If you miss the appointment, you won\'t get a refund',
        disclaimer: 'Disclaimer',
        completelyFree: 'You won’t be charged for this transaction <br> <b>It’s completely free!</b>',
        summary: 'Summary',
        occupation: 'Occupation',
        confirm: 'Confirm',
        timerTitle: 'Your seat will be reserved for',
        timeIsUp: 'Time\'s up!',
        stopBookingProcess: 'Are you sure you want to stop the booking process?',
        stopBookingInfoLost: 'If you stop the booking process, all previously entered information will be lost.',
        stopBookingYes: "Yes, I'm sure",
        stopBookingNo: 'No'
      },
      rescheduleSteps: {
        rescheduleAppointment: 'Reschedule appointment',
        rescheduleAnAppointment: 'Reschedule an appointment',
        chooseCityAndLanguage: 'Choose City and language',
        chooseDateAndSession: 'Choose Date and Session',
        cityAndLanguage: 'City and language',
        dateAndSession: 'Date and Session',
        confirmation: 'Confirmation',
        back: 'Back',
        confirm: 'Confirm',
        chooseCity: 'Choose City',
        city: 'City',
        chooseLanguage: 'Choose Language',
        language: 'Language',
        appointmentUpdated: 'Your test appointment has been updated successfully',
        stopRescheduleProcess: 'Are you sure you want to stop the reschedule process?',
        stopRescheduleInfoLost: 'If you stop the reschedule process, all previously entered information will be lost.',
        stopYes: "Yes, I'm sure",
        stopNo: 'No',
        rescheduleConfirmationDescription: "Do you want to reschedule the test date? Please confirm the chosen date, as you won't be able to make more changes."
      },
      paymentSessionConfirmation: {
        successBooking: 'Success Booking!',
        errorBooking: 'The payment was declined. Please try again',
        pendingBooking: "The payment is pending as transaction hasn't been fully processed yet. The payment result will appear on booked appointments soon.",
        viewDetails: "Don't forget to download and bring the ticket to the test center",
        backHome: 'Back home',
        underProcessingTitle: 'Payment is under processing',
        underProcessingText: 'Please wait. Do not close the page',
        pendingTitle: 'Payment result has not been received yet',
        pendingText: 'You will be notified when the status is updated',
        successText: "Don't forget to download and bring the ticket to the test center",
        successBtnText: 'View appointment details',
        withdrawnTitle: 'Reservation was not confirmed despite payment being charged to your account.',
        withDrawnText: 'We apologize for the inconvenience. Credit has been added to your wallet to make a free booking',
        timedOutTitle: 'Your session has expired',
        failedTitle: 'Payment Failed',
        failedText: 'Please try again',
        tryAgain: 'Try again',
        failedPaymentText: "Unfortunately, the payment couldn't be completed",
        back: 'Back',
        cardNumber: 'Card Number',
        expiryDate: 'Expiry Date',
        cardHolder: 'Card Holder',
        monthYear: 'MM/YY',
        cardHolderPlaceholder: 'Enter card holder name',
        cvvHint: 'The 3-digit code on back of the card',
        pay: 'Pay',
        bookNow: 'Book now'
      },
      sessionItem: {
        dateAndTime: 'Date and Time',
        duration: 'Duration',
        numberOfSeats: 'Number of seats',
        city: 'City'
      },
      summaryPage: {
        summaryPage: 'Summary Page',
        summaryDetails: 'Summary details',
        dateAndTime: 'Test Date and Time',
        duration: 'Duration',
        numberOfSeats: 'Number of seats',
        category: 'Category',
        city: 'City',
        totalAmount: 'Total Amount',
        acceptMessage: 'I declare that the information entered is correct, and that I have read, understood and agree to the',
        refundMessage: 'I declare that I have the right to get a refund for the test fee by my employer. The refund is only for one test and for the occupation specified in my work visa.',
        termsOfUse: 'Terms of Use',
        successReservation: 'View booking details on the home page',
        canceledReservation: 'The payment was declined. Please try again',
        completelyFree: 'You won’t be charged for this transaction. It’s completely <span>free</span>!',
        useYourCredit: 'Use your credit',
        transactionInProgress: 'Another transaction is in progress'
      },
      bookingDetails: {
        bookingDetails: 'Booking details',
        bookedAppointments: 'booked appointments',
        downloadCertificate: 'Download certificate',
        downloadTicket: 'Download Ticket',
        category: 'Category',
        dateAndTime: 'Test Date and Time',
        testCenter: 'Test center',
        testDuration: 'Test Duration',
        testResult: 'Test Result',
        city: 'City',
        testLocation: 'Test Location',
        viewMap: 'View map',
        bookingStatus: 'Booking Status',
        reason: 'Reason',
        enterCancelReason: 'Please enter the cancelation reason',
        confirmCancellation: 'I confirm the reservation cancellation and acknowledge that all the data entered are correct and under my responsibility',
        successMsg: 'Your test appointment has been canceled successfully!',
        cancelledBy: 'Cancelled by',
        cancellationReason: 'Cancellation Reason',
        cancelAppointmentModal: 'Cancel appointment',
        backToBooking: 'Back to booking page',
        occupationName: 'Occupation',
        occupationCode: 'Occupation Code',
        dateAndTimeText: 'at',
        testTimeZone: 'Time zone',
        language: 'Language',
        cancelAppointment: 'Cancel appointment',
        cancelationHint: 'In case of appointment cancellation, the amount <strong>will be refunded as credit to your account.</strong> This credit can be used for future bookings. We appreciate your understanding',
        cancellationReasonTitle: 'Cancellation reason',
        enterCancelationReason: 'Please enter the cancellation reason',
        cancelAppointmentConfirmation: 'I acknowledge that all the entered data is correct and is my responsibility',
        close: 'Close',
        confirmCancellationButton: 'Confirm cancellation',
        important: 'Important'
      },
      dashboard: {
        numberOfReservations: 'Number of reservations',
        numberOfCertificates: 'Number of certificates',
        paidAmount: 'Paid amount'
      },
      transactionHistory: {
        transactionHistory: 'Transaction History',
        id: 'ID',
        download: 'Download invoice',
        occupation: 'Occupation',
        occupationCode: 'Occupation Code',
        invoiceNum: 'Invoice Num',
        invoiceNumber: 'Invoice number',
        testCenter: 'Test Center',
        category: 'Category',
        dateAndTime: 'Test Date',
        status: 'Booking Status',
        createdAt: 'Created at',
        actions: 'Actions',
        enterId: 'Enter ID',
        enterInvoiceNum: 'Enter Invoice Num',
        selectStatus: 'Select Status',
        from: 'From',
        to: 'To',
        apply: 'Apply',
        clear: 'Clear',
        noTransactions: "You don't have any transactions yet",
        errorOccured: 'An error has occurred. Please try again.',
        statuses: {
          pending: 'Pending',
          success: 'Completed',
          refunded: 'Refunded',
          prepared_checkout: 'Prepared Checkout',
          failed: 'Failed',
          initiated: 'Initiated'
        },
        paymentStatus: 'Payment Status',
        paymentStatuses: {
          pending: 'Pending',
          success: 'Success',
          failed: 'Failed',
          preparedCheckout: 'Prepared Checkout'
        }
      },
      selectExam: 'Select exam sessions',
      seats: 'Number of seats',
      chooseSession: 'Choose session',
      choosen: 'Choosen',
      invalidCardData: 'Invalid card data'
    }
  },
  ar: {
    labor: {
      nav: {
        accountDashboard: 'لوحة بياناتي',
        booking: 'الحجز',
        transactionHistory: 'سجل العمليات',
        bookAnAppointment: 'حجز موعد',
        rescheduleAppointment: 'إعادة جدولة الموعد'
      },
      booking: {
        getCertified: 'احصل على الاعتماد',
        bookTest: 'احجز لاختبار الفحص المهني',
        bookAnAppointment: 'احجز موعدًا',
        bookedAppointments: 'المواعيد المحجوزة',
        emptyAppointments: 'ليس لديك أي مواعيد بعد',
        category: 'الفئة',
        dateOfBooking: 'تاريخ الحجز',
        status: 'الحالة',
        manageBooking: 'إدارة الحجوزات',
        occupation: 'المهنة',
        bookingNo: 'رقم الحجز',
        viewAppointmentDetails: 'استعراض تفاصيل الموعد',
        downloadTicket: 'تحميل التذكرة',
        downloadCertificate: 'تحميل الشهادة',
        bookingStatus: 'حالة الحجز',
        rescheduleAppointment: 'إعادة جدولة الموعد',
        testDate: 'تاريخ الاختبار'
      },
      bookingSteps: {
        bookAnAppointment: 'احجز موعدًا',
        chooseCategoryAndCity: 'اختر الفئة والمدينة',
        chooseDateAndSession: 'اختر التاريخ والجلسة',
        payment: 'الدفعات',
        paymentInfoHint: 'يُرجى إدخال تفاصيل بطاقتك لإتمام الحجز',
        chooseCategory: 'اختر الفئة',
        chooseCity: 'اختر المدينة',
        category: 'الفئة',
        city: 'المدينة',
        chooseDate: 'اخر التاريخ',
        chooseSession: 'اختر جلسة',
        chooseTimeSlot: 'اختر الوقت',
        timeSlotMsg: 'يُشار إلى الوقت وفقًا المنطقة الزمنية المحلية المدينة',
        alertMessage: 'يتم تحديد الوقت وفقًا للتوقيت المحلي للمدينة المحددة.',
        categoryDescription: 'بناء على اختيارك للمهنة فإن تصنيف اختبارك سيكون <h3>{name}</h3> نود التنويه أن آلية التحقق لدى السفارة تعتمد على التصنيف ورمز المهنة المختارة، فضلا تأكد أن المهنة المختارة مطابقة للطلب في منصة التأشيرات',
        categoryDescriptionNew: `بناء على اختيارك للمهنة فإن فئة اختبارك ستكون <br>
<h2>{name}</h2>`,
        noExams: 'عذرا لا توجد جلسات اختبار بالفئة التي تم تحديدها بناء على اختيارك للمهنة. نرجو التحقق لاحقا.',
        back: 'عودة',
        language: 'اللغة',
        chooseLanguage: 'اختر اللغة',
        selectLanguage: 'اختر اللغة',
        chooseLanguageText: 'اختر لغة الاختبار',
        occupationCode: 'رمز المهنة',
        categoryAndOccupationWillBe: 'نود التنويه أن آلية التحقق لدى السفارة تعتمد على الفئة ورمز المهنة المحددة، فضلا تأكد أن المهنة المحددة مطابقة للطلب في منصة التأشيرات.',
        important: 'هام',
        chooseOccupationAndCity: 'اختر المهنة والمدينة',
        occupationAndCity: 'المهنة والمدينة',
        chooseOccupation: 'اختر المهنة',
        noSessionsAvailable: 'عذرًا، لا يتوفر حالياً أي جلسات اختبار في الفئة المحددة بناءً على اختيارك للمهنة. يُرجى التحقق في وقت لاحق.',
        noSeatsAvailable: 'لا توجد مقاعد متاحة.يُرجى محاولة حجز جلسة اختبار أخرى.',
        disclaimerDescription: 'يمكنك تغيير تاريخ الاختبار، ولكن فقط قبل 72 ساعة من موعد الاختبار.<br> عدم حضورك للموعد يلغي حقك في استرداد رسوم الاختبار.',
        disclaimer: 'تنويه',
        completelyFree: 'لأنك تملك رصيد بالمحفظة سيكون هذا الحجز مجاني!',
        summary: 'الملخص',
        occupation: 'المهنة',
        confirm: 'تأكيد',
        timerTitle: 'سيتم حجز مقعدك لـ',
        timeIsUp: 'Time\'s up!',
        stopBookingProcess: 'هل أنت متأكد من رغبتك في إيقاف عملية الحجز؟',
        stopBookingInfoLost: 'إذا قمت بإيقاف عملية الحجز، ستفقد جميع المعلومات التي أدخلتها مسبقًا.',
        stopBookingYes: 'نعم، أنا متأكد',
        stopBookingNo: 'لا'
      },
      rescheduleSteps: {
        rescheduleAppointment: 'إعادة جدولة الموعد',
        rescheduleAnAppointment: 'إعادة جدولة الموعد',
        chooseCityAndLanguage: 'اختر المدينة واللغة',
        chooseDateAndSession: 'اختر التاريخ والجلسة',
        cityAndLanguage: 'المدينة واللغة',
        dateAndSession: 'التاريخ والجلسة',
        confirmation: 'تأكيد',
        back: 'عودة',
        confirm: 'تأكيد',
        chooseCity: 'اختر المدينة',
        city: 'المدينة',
        chooseLanguage: 'اختر اللغة',
        language: 'اللغة',
        appointmentUpdated: 'تم تحديث موعد اختبارك بنجاح!',
        stopRescheduleProcess: 'هل أنت متأكد أنك تريد إيقاف عملية إعادة الجدولة؟',
        stopRescheduleInfoLost: 'إذا قمت بإيقاف عملية إعادة الجدولة، ستفقد جميع المعلومات التي أدخلتها مسبقًا.',
        stopYes: 'نعم، أنا متأكد',
        stopNo: 'لا',
        rescheduleConfirmationDescription: 'نرجو التأكد من الموعد المحدد، حيث لا يمكنك إجراء مزيد من التعديلات. هل ترغب حقًا في إعادة جدولة موعد الاختبار؟'
      },
      paymentSessionConfirmation: {
        successBooking: 'تم الحجز!',
        errorBooking: 'تم رفض الدفع. حاول مرة اخرى',
        pendingBooking: 'الدفع قيد الانتظار لأن المعاملة لم تتم معالجتها بالكامل حتى الآن. ستظهر نتيجة الدفع لاحقا في المواعيد المحجوزة .',
        viewDetails: 'يُرجى تحميل التذكرة واحضرها معك يوم الاختبار',
        backHome: 'العودة إلى الصفحة الرئيسية',
        underProcessingTitle: 'عملية الدفع قيد التنفيذ',
        underProcessingText: 'يُرجى الانتظار وعدم اغلاق الصفحة',
        pendingTitle: 'لم يتم استلام نتيجة الدفع بعد',
        pendingText: 'سيتم ابلاغك في حين تحديث حالة الدفع',
        successText: 'يُرجى تحميل التذكرة واحضرها معك يوم الاختبار.',
        successBtnText: 'استعراض تفاصيل الموعد',
        withdrawnTitle: 'لم يتم الحجز على الرغم من الدفع',
        withDrawnText: 'نعتذر عن الإشكالية. تم إضافة رصيد للمحفظة في حسابك لإتمام حجز مجاني.',
        timedOutTitle: 'انتهت صلاحية الجلسة',
        failedTitle: 'فشل عملية الدفع',
        failedText: 'نرجو المحاولة مجددا',
        failedBtnText: 'حاول مره اخرى',
        tryAgain: 'حاول مرة أخرى',
        failedPaymentText: 'للأسف، لم يتم اكمال الطلب',
        back: 'عودة',
        cardNumber: 'رقم البطاقة',
        expiryDate: 'تاريخ انتهاء الصلاحية',
        cardHolder: 'حامل البطاقة',
        monthYear: 'سنة/شهر',
        cardHolderPlaceholder: 'أدخل اسم حامل البطاقة',
        cvvHint: 'الرمز المكوّن من 3 أرقام على ظهر البطاقة',
        pay: 'دفع',
        bookNow: 'احجز الآن'
      },
      sessionItem: {
        dateAndTime: 'التاريخ و الوقت',
        duration: 'مدة',
        numberOfSeats: 'عدد المقاعد',
        city: 'المدينة'
      },
      summaryPage: {
        summaryPage: 'صفحة الملخص',
        summaryDetails: 'تفاصيل الملخص',
        dateAndTime: 'تاريخ ووقت الاختبار',
        duration: 'مدة الجلسة',
        numberOfSeats: 'عدد المقاعد',
        category: 'الفئة',
        city: 'المدينة',
        totalAmount: 'المبلغ الإجمالي',
        acceptMessage: 'اقر بصحة المعلومات المدخلة, وبأنني قد قرأت وفهمت  وأوافق عليها',
        refundMessage: 'أقر بأني على دراية تامة بحقي في استرداد مبلغ الاختبار المدفوع للحجز من قبل صاحب العمل، على أن يقتصر الاسترداد على قيمة اختبار واحد فقط، وللمهنة المحددة في تأشيرة العمل الخاصة بي.',
        termsOfUse: 'شروط الاستخدام',
        successReservation: 'عرض تفاصيل الحجز على الصفحة الرئيسية',
        canceledReservation: 'The payment was declined. Please try again',
        completelyFree: '!لانك تملك رصيد بالمحفظة سيكون هذا الحجز <span/>مجاني<span>!',
        useYourCredit: 'مستخدم رصيدك',
        transactionInProgress: 'لديك معاملة اخرى قيد الانتظار'
      },
      bookingDetails: {
        bookingDetails: 'تفاصيل الحجز',
        bookedAppointments: 'المواعيد المحجوزة',
        downloadCertificate: 'تحميل الشهادة',
        downloadTicket: 'تحميل التذكرة',
        category: 'الفئة',
        dateAndTime: 'تاريخ ووقت الاختبار',
        testCenter: 'مركز الاختبار',
        testDuration: 'مدة الاختبار',
        testResult: 'نتيجة الاختبار',
        city: 'المدينة',
        testLocation: 'مكان الاختبار',
        viewMap: 'عرض الخريطة',
        bookingStatus: 'حالة الحجز',
        reason: 'أدخل السبب',
        enterCancelReason: 'الرجاء إدخال سبب الإلغاء',
        confirmCancellation: 'أؤكد إلغاء الحجز وأقر بأن جميع البيانات المدخلة صحيحة وتحت مسؤوليتي',
        successMsg: 'تم إلغاء موعد اختبارك بنجاح!',
        cancelledBy: 'ألغيت من قبل',
        cancellationReason: 'سبب الإلغاء',
        cancelAppointmentModal: 'لغاء الموعد',
        cancelationHint: 'في حال إلغاء الموعد، سيتم استرجاع المبلغ كرصيد في حسابك. يمكن استخدام هذا الرصيد للحجوزات المستقبلية. نقدر تفهمكم.',
        cancellationReasonTitle: 'سبب الإلغاء',
        enterCancelationReason: 'يُرجى إدخال سبب الإلغاء',
        cancelAppointmentConfirmation: 'أقر بأن جميع البيانات المُدخلة صحيحة وتحت مسؤوليتي',
        close: 'إغلاق',
        confirmCancellationButton: 'تأكيد الإلغاء',
        backToBooking: 'العودة لصفحة الحجوزات',
        occupationName: 'المهنة',
        occupationCode: 'رمز المهنة',
        dateAndTimeText: 'at',
        testTimeZone: 'المنطقة الزمنية',
        language: 'اللغة',
        cancelAppointment: 'إلغاء الموعد',
        important: 'مهم'
      },
      dashboard: {
        numberOfReservations: 'عدد الحجوزات',
        numberOfCertificates: 'عدد الشهادات',
        paidAmount: 'المبلغ المدفوع'
      },
      transactionHistory: {
        transactionHistory: 'تفاصيل العمليات',
        id: 'رقم المعرّف',
        download: 'تحميل الفاتورة',
        occupation: 'المهنة',
        occupationCode: 'رمز المهنة',
        invoiceNum: 'رقم الفاتورة',
        invoiceNumber: 'رقم الفاتورة',
        testCenter: 'مركز الاختبار',
        category: 'الفئة',
        dateAndTime: 'تاريخ الاختبار',
        status: 'حالة الحجز',
        createdAt: 'أنشئت في',
        actions: 'الإجراءات',
        enterId: 'ادخل المعرف',
        enterInvoiceNum: 'ادخال رقم الفاتورة',
        selectStatus: 'اختيار الحالة',
        from: 'من',
        to: 'الى',
        apply: 'تطبيق',
        clear: 'مسح',
        noTransactions: 'ليس لديك أي عمليات بعد',
        errorOccured: 'حدث خطأ، يرجى المحاولة مرة أخرى.',
        statuses: {
          pending: 'معلق',
          success: 'مكتمل',
          refunded: 'Refunded',
          prepared_checkout: 'معلقة',
          failed: 'غير ناجحة',
          initiated: 'Initiated'
        },
        paymentStatus: 'حالة الدفع',
        paymentStatuses: {
          pending: 'معلقة',
          success: 'ناجحة',
          failed: 'فاشلة',
          preparedCheckout: 'تجهيز الدفع'
        }
      },
      selectExam: 'حدد الجلسة',
      seats: 'عدد المقاعد',
      chooseSession: 'اختر الجلسة',
      choosen: 'مختارة',
      invalidCardData: 'معلومات البطاقة غير صحيحة'
    }
  }
};
