<template>
  <svp-layout
    :aside-is-loading="!permissionsIsFetched"
    :nav-items="navList"
    :useAside="useAside"
  >
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import IconCase from '@/app/shared/components/appShell/icons/IconCase.vue';
import IconCreditCard from '@/app/shared/components/appShell/icons/IconCreditCard.vue';
import IconTransaction from '@/app/shared/components/appShell/icons/IconTransaction.vue';
import IconUpload from '@/app/shared/components/appShell/icons/IconUpload.vue';
import IconUser from '@/app/shared/components/appShell/icons/IconUser.vue';
import IconUserFolder from '@/app/shared/components/appShell/icons/IconUserFolder.vue';
import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'LegislatorLayout',
  components: {
    SvpLayout
  },
  data () {
    return {
      pageTimeOut: 0,
      navItems: []
    };
  },
  computed: {
    ...mapGetters([
      'getIsLegislator',
      'permissionsObject',
      'permissionsLabors',
      'permissionsPayment',
      'permissionsIsFetched',
      'permissionsTestCenters'
    ]),
    isHideSessionsReportForLegislatorActiveFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_SESSIONS_AND_RESERVATIONS);
    },
    showAssessorsPageForTestCenterFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_VIEW_ASSESSORS_LIST);
    },
    showAssessorsPageForLegislatorFF () {
      return isFeatureAvailable(FeatureFlagDictionary.LEGISLATOR_VIEW_ASSESSORS_LIST);
    },
    tcStartExamFF () {
      return isFeatureAvailable(FeatureFlagDictionary.TC_START_EXAM);
    },
    useAside () {
      return this.$route.meta?.showAside;
    },
    navList () {
      const navItems = [];
      const addNavItem = (icon, descriptionPath, routeName) => {
        navItems.push({ icon, descriptionPath, routeName });
      };

      if (this.isHideSessionsReportForLegislatorActiveFF) {
        addNavItem(IconCase, 'legislator.sessionsReport', 'LegislatorSessionsReport');
      }
      if (this.permissionsLabors.view_uploaded_files) {
        addNavItem(IconUpload, 'legislator.uploadedFiles', 'LegislatorUploadPage');
      }
      if (this.permissionsLabors.view) {
        addNavItem(IconUser, 'legislator.labors', 'LegislatorLaborsPage');
      }
      if (this.showAssessorsPageForLegislatorFF) {
        addNavItem(IconUserFolder, 'legislator.assessors', 'LegislatorAssessorsPage');
      }
      if (this.permissionsPayment.make_payment) {
        addNavItem(IconCreditCard, 'legislator.payment', 'LegislatorPaymentTablePage');
      }
      if (this.permissionsTestCenters.view) {
        addNavItem(IconCase, 'legislator.testCenters', 'LegislatorTestCentersList');
      }
      if (this.permissionsPayment.view_transaction_history) {
        addNavItem(IconTransaction, 'legislator.transactionHistory', 'LegislatorTransactionHistoryPage');
        addNavItem(IconTransaction, 'legislator.reports', 'LegislatorReports');
      }
      return navItems;
    }
  },
  async created () {
    try {
      await this.getCertificatePrice();
      await this.getAccountInfo(); // This is needed for the UserInfo component to work.
    } catch (error) {
      console.log(error.response);
    }
  },
  methods: {
    ...mapActions(['SET_PAGE_LOADING', 'getCertificatePrice', 'getAccountInfo']),
    /**
     * @param {Function} cbFunction callback function
     * @param arguments You can pass arguments after 'cbFunction'
     * call example: fnWrapperWithLoader(cbFunction, obj, arr, str)
     */

    // why all this logic exist, what reason?
    fnWrapperWithLoader (cbFunction) {
      const args = Array.prototype.splice.call(arguments, 1);

      try {
        clearTimeout(this.pageTimeOut);

        this.SET_PAGE_LOADING(true); // Show loader

        cbFunction(...args);
      } catch (err) {
        console.error(err);
      }

      this.pageTimeOut = setTimeout(() => {
        this.SET_PAGE_LOADING(false); // Hide loader
      }, 1000);
    }
  }
};
</script>
