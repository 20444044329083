<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 750 750"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M694.325 0H55.325C55.125 0 54.9 0 54.65 0C24.675 0 0.35 24.1 0 53.975V695.775C0.35 725.675 24.675 749.8 54.65 749.8C54.9 749.8 55.125 749.8 55.375 749.8H694.25C694.45 749.8 694.7 749.8 694.95 749.8C724.95 749.8 749.325 725.725 749.8 695.825V695.775V54.025C749.325 24.1 724.95 0 694.925 0C694.675 0 694.45 0 694.2 0H694.325ZM222.375 638.95H111.025V281.175H222.375V638.95ZM166.725 232.225C131.1 232.225 102.25 203.35 102.25 167.75C102.25 132.15 131.125 103.275 166.725 103.275C202.325 103.275 231.2 132.125 231.2 167.725C231.2 167.75 231.2 167.775 231.2 167.825C231.2 203.4 202.35 232.25 166.775 232.25C166.75 232.25 166.725 232.25 166.7 232.25L166.725 232.225ZM638.775 638.95H527.75V464.975C527.75 423.475 526.9 370.1 469.9 370.1C412 370.1 403.175 415.25 403.175 461.925V638.975H292.15V281.2H398.8V329.975H400.25C420.95 295.1 458.4 272.1 501.225 272.1C502.75 272.1 504.25 272.125 505.75 272.175H505.525C618.025 272.175 638.825 346.225 638.825 442.6V638.975L638.775 638.95Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconLinkedin',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
