import axios from 'axios';
import moment from 'moment';

import { API_URL } from '@/config';
import LocalStorageService from '@/services/localStorageService';
import store from '@/store';
import fileReader from '@/utils/fileReader';

// URLs where we shouldn't send request for refresh toketn and should handle response 401 status
const authURLs = ['/login', '/refresh'];
const isAuthURL = (url) => authURLs.includes(url);

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use(
  async (config) => {
    config.params = config.params || {};
    config.params.locale = store.state.lang;
    const tokenLifeTime = LocalStorageService.getTokenLifeTime();
    const isTokenRefreshing = store.getters.isTokenAlreadyRefreshing;
    if (tokenLifeTime && !config.url.includes('refresh') && !isTokenRefreshing) {
      const isTokenExpired = moment().diff(tokenLifeTime) > 0;

      if (isTokenExpired) {
        await store.dispatch('refreshTokenHandler');
      }
    }

    return config;
  }
);

axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  // If response contains JSON file (Blob)
  if (
    error.request?.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    const { data } = error.response;
    // Read file
    const file = await fileReader(data);
    // Parse and retrieve content
    error.response.data = JSON.parse(file);
  }
  const originalRequest = error.config;
  const msg = (String(error.response.data?.error || '')).toLowerCase().trim();

  if (error.response.status === 401 && msg === 'signature has expired' && !isAuthURL(originalRequest.url)) {
    await store.dispatch('setTouch', false); // Hide Confirmation popup before leave page
  }

  if (error.response.status === 401 && msg === 'signature verification raised' && !isAuthURL(originalRequest.url)) {
    await store.dispatch('clearUserAccessAndRedirect', true);
  }

  return Promise.reject(error);
});

export default axios;
