export const visitorTranslations = {
  en: {
    visitor: {
      search: 'Search',
      selectCity: 'Select City',
      reload: 'Reload',
      tryAgain: 'Try again',
      testCentersAddress: 'Address',
      knowledgePage: {
        getInTouch: 'Get it touch <br/><span>with us<span>',
        homePage: 'Home page',
        ourOffice: 'Our office',
        title: 'Knowledge center',
        faq: 'FAQ',
        testCenterUserGuide: 'Test Center User Guide',
        laborUserGuide: 'Labor User Guide',
        occupations: 'Occupations',
        descFaq: 'Offers quick answers and valuable information for user queries',
        descUserGuide: 'Provides concise, user-friendly guidance for effective navigation and product usage',
        descOccupations: 'Offers a diverse catalog of professions and job roles across industries and sectors'
      }
    }
  },
  ar: {
    visitor: {
      search: 'بحث',
      selectCity: 'اختر المدينة',
      reload: 'تحميل ',
      tryAgain: 'نرجو المحاولة مجددا',
      testCentersAddress: 'العنوان',
      knowledgePage: {
        getInTouch: 'كن على تواصل معنا',
        homePage: 'الصفحة الرئيسية',
        ourOffice: 'موقعنا',
        title: 'مركز المعرفة',
        faq: 'الأسئلة الشائعة',
        testCenterUserGuide: 'الدليل الإرشادي للمركز',
        laborUserGuide: 'الدليل الإرشادي للعامل',
        occupations: 'المهن',
        descFaq: 'إجابات حول أسئلة المستخدمين الأكثر شيوعا',
        descUserGuide: 'دليل مستخدم واضح لتجربة فعالة وسهلة',
        descOccupations: 'تصنيف المهن المتوفرة'
      }
    }
  }
};
