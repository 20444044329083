import moment from 'moment';
import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';

import { assessorRoutes } from '@/app/assessor/routes';
import { authRoutes } from '@/app/auth/routes';
import { laborRoutes } from '@/app/labor/routes';
import { landingRoutes } from '@/app/landing/routes';
import { legislatorRoutes } from '@/app/legislator/routes';
import { LegislatorDefaultPathName } from '@/app/legislator/routes/utils';
import { lazyLoading } from '@/app/shared/router/utils';
import { testCenterRoutes } from '@/app/testCenter/routes';
import { TestCenterDefaultPathName } from '@/app/testCenter/routes/utils';
import { visitorRoutes } from '@/app/visitor/routes';
import Layout from '@/layouts/Layout.vue';
import LocalStorageService from '@/services/localStorageService';
import store from '@/store';
import { getEnvironmentVar } from '@/utils/environments';

Vue.use(Router);
Vue.use(Meta);

const checkViewPermission = (to, from, next) => {
  if (store.getters.permissionForViewOnly) return next({ path: '/' });
  next();
};

const router = new Router({
  mode: 'history',
  routes: [
    ...landingRoutes,
    ...visitorRoutes,
    ...authRoutes,
    ...assessorRoutes,
    ...laborRoutes,
    ...legislatorRoutes,
    ...testCenterRoutes,
    {
      path: '/',
      component: Layout,
      meta: {
        auth: true
      },
      beforeEnter (to, from, next) {
        if (!to.name) {
          next({ name: 'Home' });
        }
        const isProduction = getEnvironmentVar('ENV') === 'production';

        if (store.state.user.isLegislator) {
          return next({ name: LegislatorDefaultPathName });
        } else if (store.state.user.isTestCenterOwner) {
          return next({ name: TestCenterDefaultPathName });
        } else if (store.state.user.isLabor) {
          if (isProduction) {
            return next({ path: '/labor/booking' });
          } else {
            return next({ name: 'AccountDashboard' });
          }
        } else if (store.state.user.isAssessor) {
          return next({ name: 'UnassignedSessionsPage' });
        }
        return next({ path: '/home' });
      }
    },
    {
      path: '/success-registration',
      name: 'SuccessRegistration',
      component: lazyLoading('shared', 'SuccessRegistration')
    },
    {
      path: '/start-exam-error',
      name: 'StartExamErrorPage',
      component: lazyLoading('shared', 'errorPages/StartExamErrorPage')
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
  }
});

router.beforeEach(async (to, from, next) => {
  const lang = to.params.locale;

  if (lang) await store.dispatch('SET_LANG', lang);

  const tokenLifeTime = LocalStorageService.getTokenLifeTime();
  const isTokenExpired = moment().diff(tokenLifeTime) > 0;
  const isTokenRefreshing = store.getters.isTokenAlreadyRefreshing;
  if (tokenLifeTime && isTokenExpired && !isTokenRefreshing) {
    await store.dispatch('refreshTokenHandler');
  }

  if (store.getters.formTouched) {
    await store.dispatch('showModalBetweenRouters', { to, from, next });
  } else {
    next();
  }
});

export default router;
