<template>
  <div class="notifications-info q-notifications-box">
    <b-dropdown class="dropdown-right q-notifications">
      <div
        slot="trigger"
        class="user-info__opener"
      >
        <svp-icon
          v-if="useNewView"
          :class="{'ring': not_read_count}"
          class="icon-bell"
          height="24px"
          icon-name="IconRing"
          width="24px"
        />
        <i
          v-else
          :class="{'ring': not_read_count}"
          class="icon-bell"
        ></i>
        <span
          :class="{'show': not_read_count}"
          class="notify-num"
        >
          <span
            class="notifications_count"
            v-text="not_read_count"
          />
        </span>
      </div>
      <div
        class="dropdown-header dropdown-item"
        tabindex="0"
      >{{ $t('notifications') }}</div>
      <NotificationList v-show="notificationsLength"/>
      <a
        v-if="!notificationsLength"
        class="dropdown-item"
        href="#"
      >{{ $t('noDataAvailable') }}</a>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NotificationList from '@/components/notifications/NotificationList';

export default {
  name: 'NotificationsInfo',
  components: {
    NotificationList
  },
  props: {
    useNewView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['notifications_list', 'not_read_count']),
    notificationsLength () {
      return this.notifications_list.length;
    }
  }
};
</script>
