<template>
  <div class="svp-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1875 16.0836C15.2701 16.195 15.23 16.3591 15.1042 16.4176C14.0831 16.8926 13.2699 17.7395 12.8386 18.7842C12.7747 18.9391 12.629 19.0479 12.4615 19.0513C10.2563 19.096 8.04954 19.0396 5.8482 18.8821L4.33419 18.7738C3.36568 18.7045 2.56684 17.9885 2.39232 17.0333C1.96466 14.6927 1.89145 12.3011 2.17513 9.93877L2.44762 7.66965C2.57915 6.57432 3.50838 5.75 4.61158 5.75H6.90314C7.89547 5.75 8.69991 6.55444 8.69991 7.54677C8.69991 7.57955 8.72648 7.60612 8.75926 7.60612L17.4721 7.60612C18.5327 7.60612 19.4362 8.37679 19.6034 9.42416L19.6674 9.8256C19.7091 10.0866 19.746 10.3482 19.778 10.6102C19.7982 10.7746 19.6174 10.8909 19.4671 10.8214C19.0208 10.6151 18.5238 10.5 17.9999 10.5C16.0669 10.5 14.4999 12.067 14.4999 14C14.4999 14.7806 14.7555 15.5015 15.1875 16.0836Z"
        fill="currentColor"
      />
      <path
        d="M17.9999 12C16.8954 12 15.9999 12.8954 15.9999 14C15.9999 15.1046 16.8954 16 17.9999 16C19.1045 16 19.9999 15.1046 19.9999 14C19.9999 12.8954 19.1045 12 17.9999 12Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 20.5C13.9999 18.8431 15.3431 17.5 16.9999 17.5H18.9999C20.6568 17.5 21.9999 18.8431 21.9999 20.5C21.9999 21.0523 21.5522 21.5 20.9999 21.5H14.9999C14.4477 21.5 13.9999 21.0523 13.9999 20.5Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconAssessor',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>
