<template>
  <svp-layout
    :nav-items="navList"
    :use-aside="showAside"
    :use-new-aside="useNewLaborBookingFF"
    :use-new-header="useNewLaborBookingFF"
  >
    <router-view/>
  </svp-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SvpLayout from '@/app/shared/components/appShell/layout/SvpLayout.vue';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { getEnvironmentVar } from '@/utils/environments';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'LaborLayout',
  components: {
    SvpLayout
  },
  data () {
    return {
      pageTimeOut: 0
    };
  },
  computed: {
    ...mapGetters([
      'getIsAssessor'
    ]),
    useNewLaborBookingFF () {
      return isFeatureAvailable(FeatureFlagDictionary.USE_NEW_BOOKING);
    },
    showAside () {
      return this.$route.meta.showAside;
    },
    isProduction () {
      return getEnvironmentVar('ENV') === 'production';
    },
    navList () {
      const navItems = [];
      const addNavItem = (iconName, descriptionPath, routeName) => {
        navItems.push({ iconName, descriptionPath, routeName });
      };

      if (!this.isProduction) {
        addNavItem('IconDashboard', 'labor.nav.accountDashboard', 'AccountDashboard');
      }
      addNavItem('IconNote', 'labor.nav.booking', 'Booking');
      addNavItem('IconWallet', 'labor.nav.transactionHistory', 'LaborTransactionHistory');

      return navItems;
    }
  },
  async created () {
    if (!this.getIsAssessor) {
      try {
        await this.getCertificatePrice();
      } catch (error) {
        console.log(error.response);
      }
    }
  },
  methods: {
    ...mapActions(['getCertificatePrice'])
  }
};
</script>
