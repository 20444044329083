<template>
  <aside
    :class="{'is-open': sidebarIsOpen}"
    class="svp-aside"
  >
    <nav class="content">
      <slot>
        <template v-if="isLoading">
          <ul>
            <svp-skeleton
              v-for="(_, key) in 8"
              :key="key"
              height="50px"
              type="button"
              width="100%"
            />
          </ul>
        </template>
        <template v-else>
          <div>
            <div
              v-if="isPhone"
              class="mobile-header"
            >
              <div
                class="close-button"
                @click="closeSidebar"
              />
              <div class="language-select">
                <div>
                  <span
                    :class="{'active': isLangEng}"
                    @click="changeLanguage('en')"
                  >English</span>
                </div>
                <div class="separator">

                </div>
                <span
                  :class="{'active': !isLangEng}"
                  @click="changeLanguage('ar')"
                >
                  العربيّة
                </span>
              </div>
            </div>
            <div
              v-else
              class="logo"
            >
              <router-link :to="{ name: 'Home' }">
                <div class="logo-desktop">
                  <img
                    :src="require(`@/assets/img/svp-logo.png`)"
                    alt="desktop-logo"
                    width="200px"
                  >
                </div>
              </router-link>
            </div>
            <ul>
              <li
                v-for="(item, index) in navItems"
                :key="index"
                @click="closeSidebar"
              >
                <router-link
                  :to="{ name: item.routeName }"
                  active-class="active"
                >
                  <span>
                    <svp-icon
                      :icon-name="item.iconName"
                      height="24px"
                      width="24px"
                    />
                  </span>
                  <div>{{ $t(item.descriptionPath) }}</div>
                </router-link>
              </li>
            </ul>
          </div>
          <div
            v-if="isPhone"
            class="user-info-mobile"
          >
            <div
              class="user-info-mobile-wrapper"
              @click="showProfileLinks = !showProfileLinks"
            >
              <div>
                <div class="user-info-avatar">
                  <img
                    :src="logoSrc"
                    alt="avatar"
                  >
                </div>
                <div class="user-info-desc">
                  <div
                    class="user-info__name"
                    v-text="user.full_name"
                  />
                  <div
                    class="user-info__role"
                    v-text="role"
                  />
                </div>
              </div>
              <svp-icon
                :class="{'reverse': showProfileLinks}"
                height="20px"
                icon-name="IconChevronUp"
                width="20px"
              />
            </div>
            <svp-collapse
              :show="showProfileLinks"
              data-test-id="listOfReqCollapse"
            >
              <div class="user-profile-links">
                <router-link
                  :to="{ name: 'Home' }"
                  v-text="'User profile'"
                />
                <router-link
                  v-if="showNewChangePass"
                  :to="{ name: 'changePass' }"
                  v-text="$t('userInfo.changePassword')"
                />
                <router-link
                  v-else
                  :to="{ name: 'changePassOld' }"
                  v-text="$t('userInfo.changePassword')"
                />
                <a
                  href="/"
                  v-text="$t('userInfo.logout')"
                  @click.prevent="logout"
                />
              </div>
            </svp-collapse>
          </div>
        </template>
      </slot>
    </nav>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SvpSkeleton from '@/app/shared/components/appShell/skeleton/SvpSkeleton.vue';
import { FeatureFlagDictionary } from '@/constants/featureFlags';
import { isFeatureAvailable } from '@/utils/featureFlags';

export default {
  name: 'SvpAsideNew',
  components: { SvpSkeleton },
  props: {
    sidebarIsOpen: {
      type: Boolean,
      default: false
    },
    navItems: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      showProfileLinks: false
    };
  },
  computed: {
    ...mapGetters(['isLangEng', 'getIsLegislator', 'getIsAssessor', 'accountInfo', 'getIsLabor', 'getIsTestCenterOwner']),
    isPhone () {
      return this.windowWidth < 768;
    },
    role () {
      if (this.getIsLegislator) return this.$t('userInfo.legislator');
      if (this.getIsAssessor) return this.$t('userInfo.assessor');
      if (this.getIsLabor) return this.$t('userInfo.labor');
      if (this.getIsTestCenterOwner) return this.$t('userInfo.testCenterOwner');
      return '';
    },
    user () {
      return this.$auth.user() || {};
    },
    logoSrc () {
      return this.accountInfo ? this.accountInfo.logo_url : require('@/assets/img/user.svg');
    },
    showNewChangePass () {
      return (isFeatureAvailable(FeatureFlagDictionary.USE_NEW_CHANGE_PASSWORD));
    }
  },
  created () {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  destroyed () {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    ...mapActions(['SET_LANG', 'setTouch', 'clearPermissions']),
    closeSidebar () {
      this.$emit('closeSidebar');
    },
    updateWindowWidth () {
      this.windowWidth = window.innerWidth;
    },
    changeLanguage (lang) {
      this.SET_LANG(lang);
    },
    async logout () {
      await this.setTouch(false);
      await this.$auth.logout();
      await this.clearPermissions();
    }
  }
};
</script>

<style lang="scss" scoped>
.svp-aside {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  background-color: $color-white;
  box-shadow: 0 7px 24px rgba(66, 101, 139, .17);
  transition: transform 0.3s ease;
  overflow: hidden;

  @include media('>tablet') {
    width: $sidebar-full-width;
    transform: translateX(0);
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include media('>tablet') {
      display: block;
    }

    .mobile-header {
      margin-top: 25px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .close-button {
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 2px;
          background-color: $color-neutral-border-dark;
          transform-origin: center;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      .language-select {
        display: flex;
        gap: 25px;
        padding-top: 4px;

        div.separator {
          height: 15px;
          margin-top: 2px;
          border-right: 1px solid $color-border-lib;
        }

        span {
          cursor: pointer;
        }

        .active {
          color: $color-primary;
        }
      }

    }

    .user-info-mobile {
      margin-bottom: 60px;
      padding-top: 20px;
      border-top: 1px solid #D7DAE0;

      .user-info-mobile-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;

        .svp-icon {
          color: $color-neutral-border-dark;
          cursor: pointer;
        }

        div:first-child {
          display: flex;
          gap: 12px;
        }

        .user-info-avatar img {
          width: 50px;
          height: 50px;
        }

        .user-info-desc {
          padding-top: 4px;

          .user-info__name {
            color: $color-text;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }

          .user-info__role {
            color: $color-neutral-border-dark;
            margin-top: 2px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .user-profile-links {
        margin-top: 20px;
        width: 100%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        a {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          color: $color-font-primary;

          &:last-child {
            color: $color-red
          }

        }
      }
    }

    .logo {
      margin-bottom: 85px;

      img {
        width: 160px;
      }

      @include media('>tablet') {
        margin-top: 25px;
        margin-left: 35px;
      }

      &-mobile {
        @include media('>tablet') {
          display: none;
        }
      }

      &-desktop {
        display: none;
        @include media('>tablet') {
          display: block;
        }
      }
    }

    ul {
      padding: 20px;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      @include media('>tablet') {
        padding: 25px;
        margin-top: 0;
      }

      li {
        a {
          padding: 14px 0;
          border-radius: 4px;
          color: $color-font-primary;
          display: flex;
          gap: 15px;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          @include media('>tablet') {
            padding: 10px 0;
          }

          span {
            padding-left: 5px;
          }

          .svp-icon {
            display: flex;
            justify-content: flex-start;
            padding-left: 8px;
            color: $color-neutral-border-dark;
          }

          &:hover {
            background-color: $color-green-light2;
          }

          &.active {
            color: $color-white;
            background-color: $color-primary;

            .svp-icon {
              color: $color-white;
            }
          }
        }
      }
    }
  }

  &.is-open {
    transform: translateX(0);
  }

  .reverse {
    transform: rotate(180deg);
  }
}
</style>
