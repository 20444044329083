import RootAPI from '@/api/root.api';

const state = {
  featureFlagsMap: {}
};

const getters = {
  getFeatureFlag: (state) => (flag) => {
    return !!state.featureFlagsMap?.[flag];
  }
};

const mutations = {
  'SET_FEATURE_FLAG' (state, { flag, value }) {
    state.featureFlagsMap[flag] = value;
  },
  'SET_FEATURE_FLAGS' (state, flags) {
    const mappedFlags = flags.reduce((acc, flag) => {
      acc[flag.name] = flag.state === 'true';
      return acc;
    }, {});
    state.featureFlagsMap = { ...state.featureFlagsMap, ...mappedFlags };
  }
};
const actions = {
  getFeatureFlagsConfiguration: async ({ commit }) => {
    try {
      const response = await RootAPI.getFlipperConfiguration();
      const { feature_flags } = response.data;
      commit('SET_FEATURE_FLAGS', feature_flags);
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
