<template>
  <li class="notification-list__item">
    <i class="notification-list__icon icon-bell" />
    <span
      class="notification-list__text"
      v-text="text.message"
    />
    <span
      class="notification-list__date"
      v-text="parseDate"
    />
  </li>
</template>

<script>
import moment from 'moment';

export default {
  name: 'NotificationItem',
  props: {
    text: {
      type: Object
    },
    date: {
      type: String
    }
  },
  computed: {
    parseDate () {
      return moment(this.date, 'DD-MM-YYYY').format('DD MMM');
    }
  }
};
</script>
