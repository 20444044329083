const LocalStorageService = (() => {
  let _service;

  const getService = () => {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  };

  const getAccessToken = () => {
    return localStorage.getItem('auth_token_default');
  };

  // Get token string from 'Bearer 2eao2le291ioej219he129e92ej982je'
  const getRawAccessToken = () => {
    const accessToken = getAccessToken();
    return accessToken ? accessToken.split(' ')[1] : null;
  };

  const getUser = () => {
    const userObj = localStorage.getItem('user');
    return JSON.parse(userObj || '{}');
  };

  const setUser = (userObj) => {
    localStorage.setItem('user', JSON.stringify(userObj));
  };

  const setItem = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
  };

  const getItem = (name) => {
    return localStorage.getItem(name);
  };

  const removeItem = (name) => {
    localStorage.removeItem(name);
  };

  const setAccessToken = (token) => {
    localStorage.setItem('auth_token_default', `Bearer ${token}`);
  };

  const getTokenLifeTime = () => {
    return localStorage.getItem('token_expires_at');
  };

  const setTokenLifeTime = (time) => {
    localStorage.setItem('token_expires_at', time);
  };

  const clearTokenLifeTime = () => {
    localStorage.removeItem('token_expires_at');
  };

  const clearToken = () => {
    localStorage.removeItem('auth_token_default');
  };

  const clearUser = () => {
    localStorage.removeItem('user');
  };

  const setReservationId = (id) => {
    localStorage.setItem('reservation_id', id);
  };

  const getReservationId = () => {
    return localStorage.getItem('reservation_id');
  };

  const setPageFilters = (pageName, filterData) => {
    let allSavedFilters = localStorage.getItem('saved_filters');
    allSavedFilters = JSON.parse(allSavedFilters || '{}');
    allSavedFilters[pageName] = filterData;
    return localStorage.setItem('saved_filters', JSON.stringify(allSavedFilters));
  };

  const getPageFilters = (pageName) => {
    let allSavedFilters = localStorage.getItem('saved_filters');
    allSavedFilters = JSON.parse(allSavedFilters || '{}');
    return allSavedFilters?.[pageName] || {};
  };

  return {
    getService,
    getAccessToken,
    getRawAccessToken,
    getUser,
    setAccessToken,
    getTokenLifeTime,
    setTokenLifeTime,
    clearTokenLifeTime,
    setUser,
    setItem,
    getItem,
    removeItem,
    clearToken,
    clearUser,
    setReservationId,
    getReservationId,
    setPageFilters,
    getPageFilters
  };
})();

export default LocalStorageService;
