import LegislatorLayout from '@/app/legislator/layout/LegislatorLayout.vue';
import { checkViewPermission, LegislatorDefaultPathName } from '@/app/legislator/routes/utils';
import { lazyLoading } from '@/app/shared/router/utils';

export const legislatorRoutes = [
  {
    path: '/legislator',
    component: LegislatorLayout,
    meta: {
      auth: true
    },
    children: [
      {
        path: 'account',
        component: lazyLoading('legislator', 'AccountInformation'),
        name: 'LegislatorAccountInformation',
        meta: {
          withoutPermissions: true
        }
      },
      {
        path: 'upload',
        component: lazyLoading('legislator', 'UploadPage'),
        name: 'LegislatorUploadPage'
      },
      {
        path: 'upload/labors/:id',
        component: lazyLoading('legislator', 'LaborsGroupPage'),
        name: 'LegislatorUploadLabors'
      },
      {
        path: 'labors-list',
        component: lazyLoading('legislator', 'LaborsPage'),
        name: 'LegislatorLaborsPage'
      },
      {
        path: 'labors-list/registered',
        component: lazyLoading('legislator', 'RegisteredLaborsPage'),
        name: 'LegislatorRegisteredLaborsPage'
      },
      {
        path: 'assessors-list',
        component: lazyLoading('legislator', 'AssessorsPage'),
        name: 'LegislatorAssessorsPage'
      },
      {
        path: 'view-assessor/info/:id',
        component: lazyLoading('legislator', 'assessor/AssessorInformation'),
        name: 'LegislatorAssessorInformation',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'legislator.assessors', link: 'LegislatorAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/address/:id',
        component: lazyLoading('legislator', 'assessor/AssessorAddress'),
        name: 'LegislatorAssessorAddress',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'legislator.assessors', link: 'LegislatorAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/details/:id',
        component: lazyLoading('legislator', 'assessor/AssessorDetails'),
        name: 'LegislatorAssessorDetails',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'legislator.assessors', link: 'LegislatorAssessorsPage' }
          ]
        }
      },
      {
        path: 'view-assessor/licenses/:id',
        component: lazyLoading('shared', 'AssessorDetails/AssessorLicenses/index'),
        name: 'LegislatorAssessorLicenses',
        meta: {
          showAside: false,
          breadcrumb: [
            { name: 'legislator.assessors', link: 'LegislatorAssessorsPage' }
          ]
        }
      },
      {
        path: 'payment',
        component: lazyLoading('legislator', 'payment/PaymentPage'),
        children: [
          {
            path: '',
            props: true,
            component: lazyLoading('legislator', 'payment/PaymentTablePage'),
            name: 'LegislatorPaymentTablePage'
          },
          {
            path: 'steps',
            component: lazyLoading('legislator', 'payment/PaymentSteps'),
            name: 'LegislatorPaymentSteps'
          },
          {
            path: 'confirmation',
            props: true,
            component: lazyLoading('legislator', 'payment/PaymentConfirmation'),
            name: 'LegislatorPaymentConfirmation'
          },
          {
            path: 'confirmation/labors',
            props: true,
            component: lazyLoading('legislator', 'payment/PaidLabors'),
            name: 'LegislatorPaidLabors'
          }
        ]
      },
      {
        path: 'centers',
        component: lazyLoading('legislator', 'testCenters/TestCenters'),
        children: [
          {
            path: '',
            component: lazyLoading('legislator', 'testCenters/TestCentersList'),
            props: true,
            name: 'LegislatorTestCentersList'
          },
          {
            path: 'add',
            component: lazyLoading('legislator', 'testCenters/NewTestCenter'),
            props: true,
            beforeEnter: checkViewPermission,
            name: 'LegislatorNewTestCenter'
          },
          {
            path: ':id/edit',
            component: lazyLoading('legislator', 'testCenters/EditTestCenter'),
            props: true,
            beforeEnter: checkViewPermission,
            name: 'LegislatorEditTestCenter'
          },
          {
            path: ':id',
            component: lazyLoading('legislator', 'testCenters/ViewTestCenter'),
            props: true,
            name: 'LegislatorViewTestCenter'
          }
        ]
      },
      {
        path: 'history',
        props: true,
        component: lazyLoading('legislator', 'TransactionHistoryPage'),
        name: 'LegislatorTransactionHistoryPage'
      },
      {
        path: 'reports',
        props: true,
        component: lazyLoading('legislator', 'Reports'),
        name: 'LegislatorReports'
      },
      {
        path: 'certificate-validation',
        component: lazyLoading('legislator', 'CertificateValidation'),
        name: 'LegislatorCertificateValidation'
      },
      {
        path: 'session-report',
        component: lazyLoading('legislator', 'sessionsReport/SessionsReportPage'),
        children: [
          {
            path: '',
            component: lazyLoading('legislator', 'sessionsReport/SessionsReport'),
            name: 'LegislatorSessionsReport',
            showAside: true
          },
          {
            path: 'info/:id',
            component: lazyLoading('legislator', 'sessionsReport/SessionInformation'),
            name: 'LegislatorReportSessionInformation',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'legislator.examSessions', link: 'LegislatorSessionsReport' }
              ]
            }
          },
          {
            path: 'test-takers/:id',
            component: lazyLoading('legislator', 'sessionsReport/TestTakers'),
            name: 'LegislatorReportTestTakers',
            meta: {
              showAside: false,
              breadcrumb: [
                { name: 'legislator.examSessions', link: 'LegislatorSessionsReport' }
              ]
            }
          }
        ]
      },
      {
        path: '*',
        redirect: { name: LegislatorDefaultPathName }
      }
    ]
  }
];
